import React from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { Controller } from 'react-hook-form';

export default function MuiSelect(props) {
    return (
        <Controller
            name={props.name}
            control={props.control}
            defaultValue={0}
            render={({ value }) => <FormControlLabel {...props} labelPlacement="start" control={<Checkbox checked={Boolean(Number(value))} onChange={e => props.control.setValue(props.name, e.target.checked)} />} style={{ alignSelf: "start" }} />}
        />
    );
}