import React, { useState, useEffect } from 'react';
import { makeGlobalStyles } from '../../../styles/Styles';
import { fieldError, hideLoader, showLoader, notifyFormErrors, DepField, requiredErr, defProps, emailRegex } from '../../../app/globals';
import { useForm } from 'react-hook-form';
import * as API from '../../../app/API';
import { TextField, AppBar, Tab, Button } from '@material-ui/core';
import { TabContext, TabPanel, TabList } from '@material-ui/lab';
import AsyncAutocomplete from '../../utilities/AsyncAutocomplete';
import MuiSelect from '../../utilities/MuiSelect';
import MuiDatePicker from '../../utilities/MuiDatePicker';
import { makeNotify } from '../../utilities/Notify';
import SaveIcon from '@material-ui/icons/Save';
import NoteAdd from '@material-ui/icons/NoteAdd';
import Mail from '@material-ui/icons/Mail';
import { isObject } from 'lodash';
import { withRouter } from 'react-router-dom';

function StudentEdit(props) {
    const classes = makeGlobalStyles();
    const { history } = props;
    const isEdit = props.match.params.action === 'edit';
    const studentid = isEdit ? props.match.params.id : null;
    const { register, handleSubmit, setValue, getValues, watch, control, errors } = useForm({ shouldUnregister: false });
    // State
    const [tab, setTab] = useState("0");
    const [visaRequired, setVisaRequired] = useState(true);
    const complete = watch("status", false);
    const visaField = watch("visa");
    const order = watch("order");
    // const [formFields, setFormFields] = useState(null); // needed for unmounted fields (tabs,steps)
    // Functions
    const onTabChange = (event, newValue) => { /*setFormFields({ ...formFields, ...getValues() });*/ setTab(newValue) }

    const buttonAction = async (action, blank = false) => {
        // const buttonAction = async (action, single = false, responseType = 'blob') => {
        let actionLink = `${API.api + action}/${studentid}`;
        blank ? window.open(actionLink) : window.location.assign(actionLink);
        // const blob = await API.getRequest(`${action}${single ? `/${studentid}` : `?ids=[${studentid}]`}`, false, false, responseType);
        // if (blob) {
        //     const url = window.URL.createObjectURL(blob);
        //     const a = document.createElement('a');
        //     a.style.display = 'none';
        //     a.href = url;
        //     a.download = `${action}.zip`;
        //     document.body.appendChild(a);
        //     a.click();
        //     window.URL.revokeObjectURL(url);
        // }
    }

    const onSave = async (data) => {
        showLoader();
        let fields = getValues();
        // console.log(fields);
        let formData = {};
        for (var [key, field] of Object.entries(fields)) {
            if (key === 'italy_comune_obj') key = 'italy_comune_code';
            if (key === 'nationality_country_obj') key = 'nationality_country_code';
            if (key === 'birth_country_obj') !isObject(formData.birth_city) ? key = 'birth_country_code' : delete formData.birth_country_obj;
            if (key === 'residence_country_obj') !isObject(formData.residence_city) ? key = 'residence_country_code' : delete formData.residence_country_obj;
            if (key === 'service') key = 'student_service_id';
            if (key === 'status') key = 'student_status_id';
            if (key === 'visa') key = 'student_visa_id';
            if (key === 'airport') key = 'student_airport_id';
            if (key === 'motive') key = 'student_motive_id';
            if (key === 'passport_country_obj' && isObject(field)) { key = 'passport_country_short'; field = field.iso }
            if (key === 'residence_city' && isObject(field)) { let obj = await API.getRequest(`nazioniiso/${field.countryCode}`); formData.residence_country_code = obj.code; field = field.toponymName }
            if (key === 'birth_city' && isObject(field)) { let obj = await API.getRequest(`nazioniiso/${field.countryCode}`); formData.birth_country_code = obj.code; field = field.toponymName }
            if (isObject(field)) field = field.id ? field.id : field.code;
            formData[key] = field;
        }
        let saved = isEdit ? await API.patchRequest(`students/${studentid}`, formData) : await API.postRequest(`students`, formData);
        makeNotify(saved.ok ? "Save completed" : `Error ${saved.status}. Try again`);
        if (saved.ok && !isEdit) history.push(`/studenti/edit/${saved.id}`);
        hideLoader();
    }

    const sendMail = async () => {
        showLoader()
        let sendmail = await API.getRequest(`paymentreminder?student_id=${studentid}`)
        alert(sendmail.ok ? "Mail sent!" : "ERROR")
        hideLoader()
    }

    // Hooks
    useEffect(() => {
        // console.log(visaField);
        if ((visaField?.id === 1 || visaField?.id === 5) && visaRequired === true)
            setVisaRequired(false);
        else if (visaField?.id !== 1 && visaField?.id !== 5 && visaRequired === false)
            setVisaRequired(true);
        // eslint-disable-next-line
    }, [visaField])
    useEffect(() => {
        (async () => {
            if (isEdit) {
                let data = await API.getRequest(`students/${studentid}`);
                console.log(data);
                // setFormFields(data); // needed for unmounted fields (tabs,steps)
                for (const field in data) setValue(field, data[field]);
            }
            hideLoader();
        })();
        return () => showLoader();
        // eslint-disable-next-line
    }, []);
    // useEffect(() => { for (const field in formFields) setValue(field, formFields[field]) }, [tab, formFields, setValue])
    useEffect(() => { notifyFormErrors(Object.values(errors)) }, [errors])
    // useEffect(() => { console.log(getValues('status')) }, [complete, getValues])

    return (
        <form id="studentForm" onSubmit={handleSubmit(onSave)} autoComplete='off' className={classes.accountForm}>
            <div className={classes.formActions}>
                <span className={`material-icons ${classes.iconCompleted} ${complete?.id === 2 ? classes.dFlex : classes.dNone}`}>check_circle_outline</span>
                <Button type="submit" variant="contained" color="primary" size="medium" startIcon={<SaveIcon />} style={{ marginRight: "10px" }}>Save</Button>
                <Button variant="contained" color="secondary" size="medium" startIcon={<NoteAdd />} style={{ marginRight: "10px" }} onClick={() => buttonAction('m209')}>M209</Button>
                <Button variant="contained" color="secondary" size="medium" startIcon={<NoteAdd />} style={{ marginRight: "10px" }} onClick={() => buttonAction('pse', true)}>P.S.E.</Button>
                <Button variant="contained" color="secondary" size="medium" startIcon={<NoteAdd />} style={{ marginRight: "10px" }} onClick={() => buttonAction('dop', true)}>DOP</Button>
                <Button variant="contained" color="secondary" size="medium" startIcon={<NoteAdd />} style={{ marginRight: "10px" }} onClick={() => buttonAction('fiscal')}>Fiscal Code</Button>
                {!order?.transaction_id && <Button variant="contained" color="secondary" size="medium" startIcon={<Mail />} style={{ marginRight: "10px" }} onClick={() => sendMail()}>NP Mail</Button>}
            </div>
            <div className={`${classes.dFlex} ${classes.m0a} ${classes.flexColumn} ${classes.alignItemsCenter}`} style={{ marginBottom: "30px" }}>
                <h4>Online Payment: {order?.transaction_id ? 'COMPLETED' : 'NOT COMPLETED'}</h4>
                <Button variant="outlined" color="primary" onClick={e => { navigator.clipboard.writeText(e?.target?.dataset?.link); makeNotify('Copied to ClipBoard') }}>
                    {/* <span data-link={`${window.location.origin}/payment/${studentid}`}>Payment Link</span> */}
                    <span data-link={`${API.api}payment?student_id=${studentid}`}>Payment Link</span>
                </Button>
            </div>
            <div className={`${classes.dFlex} ${classes.flexWrap} ${classes.alignItemsCenter}`}>
                <div className={classes.formBlock} data-name="Basic" style={{ flexGrow: "1", flexBasis: "400px" }}>
                    <TextField {...defProps} id="first_name" name="first_name" label='First Name' inputRef={register({ required: requiredErr })} {...fieldError('first_name', errors)} required />
                    <TextField {...defProps} id="middle_name" name="middle_name" label='Middle Name' inputRef={register} />
                    <TextField {...defProps} id="last_name" name="last_name" label='Last Name' inputRef={register({ required: requiredErr })} {...fieldError('last_name', errors)} required />
                    <MuiSelect {...defProps} id="gender" name="gender" label="Gender" control={control} rules={{ required: true }} options={[{ value: "M", label: "Male" }, { value: "F", label: "Female" }]} {...fieldError('gender', errors)} required />
                </div>
                <div className={classes.formBlock} data-name="Management" style={{ flexGrow: "1", flexBasis: "400px" }}>
                    <AsyncAutocomplete {...defProps} id="service" name="service" label="Service" rules={{ required: requiredErr }} endpoint="studentservices" control={control} {...fieldError('service', errors)} required />
                    <DepField {...defProps} id="partner" name="partner" label='Partner' parentfield='service' control={control} inputRef={register({ required: requiredErr })} {...fieldError('partner', errors)} required />
                    <AsyncAutocomplete {...defProps} id="status" name="status" label="Status" rules={{ required: requiredErr }} {...fieldError('status', errors)} endpoint="studentstatuses" control={control} />
                    <TextField {...defProps} id="italy_school" name="italy_school" label='School in Italy' inputRef={register({ required: requiredErr, maxLength: { value: 23, message: '23 characters at most' } })} {...fieldError('italy_school', errors)} required />
                    <TextField
                        {...defProps} id="email" name="email" label='Email' {...fieldError('email', errors)} required
                        inputRef={register({ required: requiredErr, pattern: { value: emailRegex, message: 'Email non valida' } })}
                    />
                </div>
            </div>

            <div className={`${classes.dFlex} ${classes.flexWrap} ${classes.alignItemsCenter}`}>
                <TabContext value={tab}>
                    <AppBar position="static">
                        <TabList onChange={onTabChange}>
                            <Tab label="At Italy" value="0" />
                            <Tab label="Locations" value="1" />
                            <Tab label="Administrative" value="2" />
                        </TabList>
                    </AppBar>
                    <TabPanel value="0" className={classes.tabPanel}>
                        <div className={`${classes.dFlex} ${classes.flexWrap} ${classes.alignItemsCenter}`}>
                            <div className={`${classes.formBlock} tab-formBlock`} data-name="Arrival" style={{ flexGrow: "1", flexBasis: "400px" }}>
                                <MuiDatePicker {...defProps} id="arrival_date" name="arrival_date" label="Arrival Date" control={control} format="yyy-MM-dd" {...fieldError('arrival_date', errors)} />
                                <AsyncAutocomplete {...defProps} id="airport" name="airport" label="Airport" rules={{ required: requiredErr }} {...fieldError('airport', errors)} endpoint="studentairports" control={control} required />
                                <AsyncAutocomplete {...defProps} id="motive" name="motive" label="Travel Purpose" rules={{ required: requiredErr }} {...fieldError('motive', errors)} endpoint="studentmotives" control={control} required />
                            </div>
                            <div className={`${classes.formBlock} tab-formBlock`} data-name="Residence (at Italy)" style={{ flexGrow: "1", flexBasis: "400px" }}>
                                <div className={classes.dFlex}>
                                    <div style={{ flexGrow: "3", marginRight: "20px" }}>
                                        <TextField {...defProps} id="italy_address" name="italy_address" label='Address' inputRef={register({ required: requiredErr })} {...fieldError('italy_address', errors)} required />
                                    </div>
                                    <div style={{ flexGrow: "1" }}>
                                        <TextField {...defProps} id="italy_civic" name="italy_civic" label='Civic' inputRef={register} {...fieldError('italy_civic', errors)} type="number" />
                                    </div>
                                </div>
                                <TextField {...defProps} id="italy_zip" name="italy_zip" label='Postal Code' inputRef={register({ required: requiredErr })} {...fieldError('italy_zip', errors)} type="number" required />
                                <AsyncAutocomplete {...defProps} isSearch id="italy_comune_obj" name="italy_comune_obj" label="City" rules={{ required: requiredErr }} endpoint="comuni" control={control} {...fieldError('italy_comune_obj', errors)} required />
                                <DepField {...defProps} id="italy_province" name="italy_province" label='Province' control={control} inputRef={register({ required: requiredErr })} {...fieldError('italy_province', errors)} parentfield='italy_comune_obj' parentprop="province" required />
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel value="1" className={classes.tabPanel}>
                        <div className={`${classes.dFlex} ${classes.flexWrap} ${classes.alignItemsCenter}`}>
                            <div className={`${classes.formBlock} tab-formBlock`} data-name="Birth" style={{ flexGrow: "1", flexBasis: "400px" }}>
                                <MuiDatePicker {...defProps} id="birth_date" name="birth_date" label="Birth Date" control={control} format="yyy-MM-dd" {...fieldError('birth_date', errors)} rules={{ required: requiredErr }} required />
                                <AsyncAutocomplete {...defProps} isGeoNames id="birth_city" name="birth_city" label="City" control={control} rules={{ required: requiredErr }} {...fieldError('birth_city', errors)} required />
                                <DepField {...defProps} id="birth_state" name="birth_state" label='State' control={control} inputRef={register} {...fieldError('birth_state', errors)} parentfield='birth_city' parentprop="adminName1" />
                                <DepField {...defProps} id="birth_country" name="birth_country" label='Country' control={control} inputRef={register} {...fieldError('birth_country', errors)} parentfield='birth_city' parentprop="countryName" />
                                <AsyncAutocomplete {...defProps} isSearch id="nationality_country_obj" name="nationality_country_obj" label="Nationality Country" endpoint="nazioni" control={control} rules={{ required: requiredErr }} {...fieldError('nationality_country_obj', errors)} required />
                            </div>
                            <div className={`${classes.formBlock} tab-formBlock`} data-name="Residence" style={{ flexGrow: "1", flexBasis: "400px" }}>
                                <TextField {...defProps} id="residence_address" name="residence_address" label='Address' inputRef={register({ required: requiredErr })} {...fieldError('residence_address', errors)} required />
                                <TextField {...defProps} id="residence_zip" name="residence_zip" label='Postal Code' inputRef={register({ required: requiredErr })} {...fieldError('residence_zip', errors)} required /*type="number"*/ />
                                <AsyncAutocomplete {...defProps} isGeoNames id="residence_city" name="residence_city" label="City" control={control} rules={{ required: requiredErr }} {...fieldError('residence_city', errors)} required />
                                <DepField {...defProps} id="residence_state" name="residence_state" label='State' control={control} inputRef={register} {...fieldError('residence_state', errors)} parentfield='residence_city' parentprop="adminName1" />
                                <DepField {...defProps} id="residence_country" name="residence_country" label='Country' control={control} inputRef={register} {...fieldError('residence_country', errors)} parentfield='residence_city' parentprop="countryName" />
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel value="2" className={classes.tabPanel}>
                        <div className={`${classes.dFlex} ${classes.flexWrap} ${classes.alignItemsCenter}`}>
                            <div className={`${classes.formBlock} tab-formBlock`} data-name="Passport" style={{ flexGrow: "1", flexBasis: "400px" }}>
                                <TextField {...defProps} id="passport_number" name="passport_number" label='Passport Number' inputRef={register({ required: requiredErr })} {...fieldError('passport_number', errors)} required />
                                <MuiDatePicker {...defProps} id="passport_start" name="passport_start" label="Issue Date" control={control} format="yyy-MM-dd" {...fieldError('passport_start', errors)} rules={{ required: requiredErr }} required />
                                <MuiDatePicker {...defProps} id="passport_end" name="passport_end" label="Expiration Date" control={control} format="yyy-MM-dd" {...fieldError('passport_end', errors)} rules={{ required: requiredErr }} required />
                                <AsyncAutocomplete {...defProps} isSearch id="passport_country_obj" name="passport_country_obj" label="Issue Country" endpoint="nazioni" control={control} rules={{ required: requiredErr }} {...fieldError('passport_country_obj', errors)} required />
                                <TextField {...defProps} id="passport_authority" name="passport_authority" label='Issuing Authority' inputRef={register({ required: requiredErr })} {...fieldError('passport_authority', errors)} required />
                            </div>
                            <div className={`${classes.formBlock} tab-formBlock`} data-name="Visa" style={{ flexGrow: "1", flexBasis: "400px" }}>
                                <div className={classes.dFlex}>
                                    <div style={{ flexGrow: "1", marginRight: "20px" }}>
                                        <AsyncAutocomplete {...defProps} id="visa" name="visa" label="Type" rules={{ required: requiredErr }} {...fieldError('visa', errors)} endpoint="studentvisas" control={control} required />
                                    </div>
                                    <div style={{ flexGrow: "1" }}>
                                        <TextField {...defProps} id="visa_days" name="visa_days" label='Days of Validity' inputRef={register({ required: visaRequired ? requiredErr : false })} {...fieldError('visa_days', errors)} required={visaRequired} type="number" />
                                    </div>
                                </div>
                                <div className={classes.dFlex}>
                                    <div style={{ flexGrow: "1", marginRight: "20px" }}>
                                        <TextField {...defProps} id="visa_consulate" name="visa_consulate" label='Consulate' inputRef={register({ required: visaRequired ? requiredErr : false })} {...fieldError('visa_consulate', errors)} required={visaRequired} />
                                    </div>
                                    <div style={{ flexGrow: "1" }}>
                                        <MuiSelect {...defProps} id="visa_entries" name="visa_entries" label="Nº Entries" control={control} rules={{ required: true }} options={[{ value: 0, label: "Single" }, { value: 1, label: "Multiple" }]} {...fieldError('visa_entries', errors)} required={visaRequired} />
                                    </div>
                                </div>
                                <TextField {...defProps} id="visa_number" name="visa_number" label='Number' inputRef={register({ required: visaRequired ? requiredErr : false })} {...fieldError('visa_number', errors)} required={visaRequired} type="number" />
                                <MuiDatePicker {...defProps} id="visa_start" name="visa_start" label="Start Date" control={control} format="yyy-MM-dd" {...fieldError('visa_start', errors)} rules={{ required: visaRequired ? requiredErr : false }} required={visaRequired} />
                                <MuiDatePicker {...defProps} id="visa_end" name="visa_end" label="Expiration Date" control={control} format="yyy-MM-dd" {...fieldError('visa_end', errors)} rules={{ required: visaRequired ? requiredErr : false }} required={visaRequired} />
                            </div>
                        </div>
                    </TabPanel>
                </TabContext>
            </div>
        </form>
    )
}

export default withRouter(StudentEdit);