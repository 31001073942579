import React, { useState, useEffect } from 'react';
import { makeGlobalStyles } from '../../../styles/Styles';
import { fieldError, notifyFormErrors, hideLoader, showLoader, requiredErr, defProps } from '../../../app/globals';
import * as API from '../../../app/API';
import { TextField, Button, Switch, Typography, DialogTitle, List, ListItem, Dialog, Paper } from '@material-ui/core';
import AsyncAutocomplete from '../../utilities/AsyncAutocomplete';
import MuiCheckbox from '../../utilities/MuiCheckbox';
import MuiDatePicker from '../../utilities/MuiDatePicker';
import { makeNotify } from '../../utilities/Notify';
import SaveIcon from '@material-ui/icons/Save';
import { isObject } from 'lodash';
import { withRouter } from 'react-router-dom';
import MaterialTable from 'material-table';
import { useForm } from 'react-hook-form';

function ServiceEdit(props) {
    const classes = makeGlobalStyles();
    const { history } = props;
    const isEdit = props.match.params.action === 'edit';
    const serviceid = isEdit ? props.match.params.id : null;
    const { register, handleSubmit, setValue, getValues, /*watch,*/ control, errors } = useForm();
    // const watchAllFields = watch();
    // Functions
    const getService = async () => await API.getRequest(`studentservices/${serviceid}`);
    // -- Parts
    const [columns, setColumns] = useState([]);
    const [parts, setParts] = useState([]);
    const [open, setOpen] = useState(false);
    const [partsConcepts, setPartsConcepts] = useState(null);
    const addPart = part => closeDialog(part)
    const editNumber = row => <TextField value={row.value} onChange={e => row.onChange(e.target.value)} type="number" />
    const editSwitch = row => <Switch checked={row.value ? true : false} onChange={e => row.onChange(e.target.checked ? 1 : 0)} />
    const updateTable = parts => {
        if (parts && parts.length) {
            setColumns([
                { title: "Type", field: "concept.id", editable: 'never', render: row => <Typography>{row?.concept?.name}</Typography> },
                { title: "Tax", field: "tax", editComponent: row => editNumber(row) },
                { title: "Value", field: "value", editComponent: row => editNumber(row) },
                { title: "Optional", field: "optional", render: row => <Switch checked={row.optional ? true : false} />, editComponent: row => editSwitch(row) },
                { title: "Payment", field: "payment", render: row => <Switch checked={row.payment ? true : false} />, editComponent: row => editSwitch(row) }
            ]);
            setParts(parts);
        }
    }
    const savePart = async (part, create = false) => {
        showLoader();
        // console.log(part);
        let servicePart = create
            ? { student_service_id: serviceid, student_service_part_concept_id: part?.concept?.id || part?.id, tax: part.default_tax, value: part.default_value, optional: part.default_optional, payment: 1 }
            : { student_service_id: serviceid, student_service_part_concept_id: part?.concept?.id || part?.id, tax: part.tax, value: part.value, optional: part.optional, payment: part.payment };
        let save = create
            ? await API.postRequest(`studentserviceparts`, servicePart)
            : await API.patchRequest(`studentserviceparts/${part.id}`, servicePart);
        console.log(save);
        let pts = (await getService()).parts;
        setParts(pts);
        updateTable(pts);
        hideLoader();
    }
    const openDialog = () => setOpen(true)
    const closeDialog = async (part) => {
        setOpen(false);
        if (part) await savePart(part, true);
    }
    const onSave = async (data) => {
        showLoader();
        let fields = getValues();
        console.log(fields);
        let formData = {};
        for (var [key, field] of Object.entries(fields)) {
            if (key === 'parts') { formData[key] = parts; break }
            if (key === 'partner') key = 'partner_id';
            if (isObject(field)) field = field.id ? field.id : field.code;
            formData[key] = field;
        }
        // console.log(formData);
        let saved = isEdit ? await API.patchRequest(`studentservices/${serviceid}`, formData) : await API.postRequest(`studentservices`, formData);
        makeNotify(saved.ok ? "Save completed" : `Error ${saved.status}. Try again`);
        if (saved.ok && !isEdit) history.push(`/servizi/edit/${saved.id}`);
        hideLoader();
    }
    // Hooks
    useEffect(() => {
        (async () => {
            if (isEdit) {
                let data = await getService();
                // console.log(data);
                updateTable(data.parts);
                for (const field in data) setValue(field, data[field]);
            }
            setPartsConcepts((await API.getRequest(`studentservicepartconcepts`))?.data);
            hideLoader();
        })();
        return () => showLoader();
        // eslint-disable-next-line
    }, []);
    useEffect(() => { notifyFormErrors(Object.values(errors)) }, [errors])
    // useEffect(() => { console.log(getValues()) }, [watchAllFields]);

    return (
        <div>
            <form id="serviceForm" className={classes.indexForm} onSubmit={handleSubmit(onSave)} autoComplete="off">
                <div className={classes.formActions}>
                    <Button type="submit" variant="contained" color="primary" size="medium" startIcon={<SaveIcon />} style={{ marginRight: "10px" }}>Save</Button>
                </div>
                <div className={`${classes.dFlex} ${classes.flexWrap} ${classes.alignItemsCenter}`}>
                    <div className={classes.formBlock} data-name="Basic" style={{ flexGrow: "1", flexBasis: "400px" }}>
                        <TextField {...defProps} id="name" name="name" label='Name' inputRef={register({ required: requiredErr })} {...fieldError('name', errors)} required />
                        <TextField {...defProps} id="link" name="link" label='Link' inputRef={register({ required: requiredErr })} {...fieldError('link', errors)} required />
                        <AsyncAutocomplete {...defProps} id="partner" name="partner" label="Partner" rules={{ required: requiredErr }} endpoint="partners" control={control} {...fieldError('partner', errors)} required />
                        <MuiCheckbox label="Link Enabled" id="link_enabled" name="link_enabled" control={control} />
                    </div>
                    <div className={classes.formBlock} data-name="Other" style={{ flexGrow: "1", flexBasis: "400px" }}>
                        <TextField {...defProps} id="bollettino_price" name="bollettino_price" label='Bollettino Price' inputRef={register({ required: requiredErr })} {...fieldError('bollettino_price', errors)} required type="number" inputProps={{ step: "0.01" }} />
                        <MuiDatePicker {...defProps} id="start_at" name="start_at" label="Initial Date" control={control} format="yyy-MM-dd" {...fieldError('start_at', errors)} />
                        <MuiDatePicker {...defProps} id="end_at" name="end_at" label="End Date" control={control} format="yyy-MM-dd" {...fieldError('end_at', errors)} />
                        <MuiCheckbox label="Online Payment" id="online_payment" name="online_payment" control={control} />
                        <MuiCheckbox label="Enable external management" id="externalManagement" name="externalManagement" control={control} />
                        <TextField {...defProps} id="externalManagementText" name="externalManagementText" label='External management text' inputRef={register} {...fieldError('externalManagementText', errors)} />
                    </div>
                    {isEdit ? (
                        <div className={`${classes.formBlock} ${classes.formBlockWithTable}`} data-name="Parts">
                            <MaterialTable
                                title="Part Types" columns={columns} data={parts} options={{ paging: false }}
                                localization={{ body: { editRow: { deleteText: 'Are you sure to delete the Service Part?' } } }}
                                actions={[
                                    {
                                        icon: 'add',
                                        tooltip: 'Add Part',
                                        onClick: (event, rows) => openDialog(),
                                        isFreeAction: true
                                    },
                                ]}
                                editable={{
                                    onRowUpdate: async (newData, oldData) => {
                                        // console.log(newData, oldData);
                                        await savePart(newData)
                                        return;
                                    },
                                    onRowDelete: async (oldData) => {
                                        // console.log(oldData);
                                        await API.deleteRequest(`studentserviceparts/${oldData.id}`);
                                        return;
                                    }
                                }}
                                components={{ Container: props => <Paper {...{...props, className: classes.materialTable}} /> }}
                            />
                            <Dialog onClose={() => closeDialog(null)} open={open}>
                                <DialogTitle>Select Default Part</DialogTitle>
                                <List>
                                    {partsConcepts ? Object.values(partsConcepts).map(part => (
                                        <ListItem onClick={() => addPart(part)} key={part.name}>
                                            <Button className={classes.m0a}>{part.name}</Button>
                                        </ListItem>
                                    )) : null}
                                </List>
                            </Dialog>
                        </div>
                    ) : null}
                </div>
            </form>
        </div >
    )
}

export default withRouter(ServiceEdit);