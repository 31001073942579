import React, { useEffect } from 'react';
import { makeGlobalStyles } from '../../../styles/Styles';
import { fieldError, notifyFormErrors, hideLoader, showLoader, DepField, requiredErr, defProps } from '../../../app/globals';
import * as API from '../../../app/API';
import { TextField, Button } from '@material-ui/core';
import AsyncAutocomplete from '../../utilities/AsyncAutocomplete';
import MuiCheckbox from '../../utilities/MuiCheckbox';
import { makeNotify } from '../../utilities/Notify';
import SaveIcon from '@material-ui/icons/Save';
import { isObject } from 'lodash';
import { withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';

function PartnerEdit(props) {
    const classes = makeGlobalStyles();
    const { history } = props;
    const isEdit = props.match.params.action === 'edit';
    const partnerid = isEdit ? props.match.params.id : null;
    const { register, handleSubmit, setValue, getValues, control, errors } = useForm();
    // Functions
    const onSave = async (data) => {
        showLoader();
        let fields = getValues();
        // console.log(fields);
        let formData = {};
        for (var [key, field] of Object.entries(fields)) {
            if (key === 'city' && isObject(field)) field = field.name;
            if (isObject(field)) field = field.id ? field.id : field.code;
            formData[key] = field;
        }
        // console.log(formData);
        let saved = isEdit ? await API.patchRequest(`partners/${partnerid}`, formData) : await API.postRequest(`partners`, formData);
        makeNotify(saved.ok ? "Save completed" : `Error ${saved.status}. Try again`);
        if (saved.ok && !isEdit) history.push(`/partners/edit/${saved.id}`);
        hideLoader();
    }
    // Hooks
    useEffect(() => { // componentDidMount
        (async () => {
            if (isEdit) {
                let data = await API.getRequest(`partners/${partnerid}`);
                // console.log(data);
                for (const field in data) setValue(field, data[field]);
            }
            hideLoader();
        })();
        return () => showLoader(); // componentWillUnmount
    }, [isEdit, partnerid, setValue]);
    useEffect(() => { notifyFormErrors(Object.values(errors)) }, [errors])

    return (
        <div>
            <form id="partnerForm" className={classes.indexForm} onSubmit={handleSubmit(onSave)} autoComplete="off">
                <div className={classes.formActions}>
                    <Button type="submit" variant="contained" color="primary" size="medium" startIcon={<SaveIcon />} style={{ marginRight: "10px" }}>Save</Button>
                </div>
                <div className={`${classes.dFlex} ${classes.flexWrap} ${classes.alignItemsCenter}`}>
                    <div className={classes.formBlock} data-name="Basic" style={{ flexGrow: "1", flexBasis: "400px" }}>
                        <TextField {...defProps} id="name" name="name" label='Name' inputRef={register({ required: requiredErr })} {...fieldError('name', errors)} required />
                        <TextField {...defProps} id="link_prefix" name="link_prefix" label='Link Prefix' inputRef={register({ required: requiredErr })} {...fieldError('link_prefix', errors)} required />
                        <MuiCheckbox label="Is Member" id="is_member" name="is_member" control={control} />
                    </div>
                    <div className={classes.formBlock} data-name="Location" style={{ flexGrow: "1", flexBasis: "400px" }}>
                        <TextField {...defProps} id="address" name="address" label='Address' inputRef={register} {...fieldError('address', errors)} />
                        <TextField {...defProps} id="civic" name="civic" label='Civic' inputRef={register} {...fieldError('civic', errors)} />
                        <TextField {...defProps} id="zip" name="zip" label='Zip Code' inputRef={register} {...fieldError('zip', errors)} />
                        <AsyncAutocomplete {...defProps} isSearch id="city" name="city" label="City" endpoint="comuni" control={control} {...fieldError('city', errors)} />
                        <DepField {...defProps} id="province_code" name="province_code" label='Province Code' parentfield='city' parentprop='province' control={control} inputRef={register} {...fieldError('province_code', errors)} />
                        <DepField {...defProps} style={{ display: 'none' }} id="comune_code" name="comune_code" label='Comune Code' parentfield='city' parentprop='code' control={control} inputRef={register} {...fieldError('comune_code', errors)} />
                        <TextField {...defProps} id="region" name="region" label='Region' inputRef={register} {...fieldError('region', errors)} />
                        <TextField {...defProps} disabled label="Country" type="text" id="country" name="country" inputRef={register} defaultValue='Italia' />
                        <TextField {...defProps} disabled style={{ display: 'none' }} label="Country Code" type="text" id="country_name_short" name="country_name_short" inputRef={register} defaultValue='IT' />
                    </div>
                    <div className={classes.formBlock} data-name="Contact" style={{ flexGrow: "1", flexBasis: "400px" }}>
                        <TextField {...defProps} id="phone" name="phone" label='Phone' inputRef={register} {...fieldError('phone', errors)} />
                        <TextField
                            {...defProps} id="email" name="email" label='Email' {...fieldError('email', errors)}
                            inputRef={register({ pattern: { value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, message: 'Email non valida' } })}
                        />
                        <TextField {...defProps} id="website" name="website" label='Website' inputRef={register} {...fieldError('website', errors)} />
                    </div>
                </div>
            </form>
        </div >
    )
}

export default withRouter(PartnerEdit);