import React, { useState, useEffect, useCallback } from 'react';
import { history } from "../../index";
import moment from 'moment';
import { makeGlobalStyles } from '../../styles/Styles';
import MaterialTable, { MTableToolbar } from 'material-table';
import { TablePagination, Button, IconButton, Icon, TextField, InputAdornment } from '@material-ui/core';
import * as API from '../../app/API';
import { hideLoader, showLoader } from '../../app/globals';

function Services() {
    const pagBase = 'studentservices?page=';
    const classes = makeGlobalStyles();
    // State
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [currPage, setCurrPage] = useState(0);
    // Functions 
    const updateTable = async (endpoint, page) => {
        showLoader();
        let servicesdata = await API.getRequest(endpoint);
        let services = servicesdata.data;
        services.map(x => { x.start_at = moment(x.start_at).format('yyy-MM-D'); x.end_at = moment(x.end_at).format('yyy-MM-D'); return x; });
        // console.log(services)
        setTotal(servicesdata.meta.total);
        setCurrPage(page);
        if (services && services.length) {
            setColumns([
                { title: "Nome", field: "name" },
                { title: "Partner", field: "partner.name" },
                { title: "Initial Date", field: "start_at" },
                { title: "End Date", field: "end_at" }
            ]);
            setData(services);
        }
        setSearch((new URLSearchParams(endpoint)).get("name"));
        hideLoader();
    }
    const newService = () => {
        showLoader();
        history.push("/servizi/new");
    }
    // Search
    const [search, setSearch] = useState('');
    // eslint-disable-next-line
    const searchFn = useCallback(API.debounce(updateTable, 400), []);
    // Hooks
    useEffect(() => {
        (async () => await updateTable(`${pagBase}0`, 0))();
        return () => showLoader(); // componentWillUnmount
        // eslint-disable-next-line
    }, []);

    return (
        <MaterialTable
            title="Services"
            columns={columns}
            data={data}
            options={{
                search: false,
                selection: true,
                pageSize: 15,
                pageSizeOptions: [15]
            }}
            actions={[
                {
                    icon: 'search',
                    tooltip: 'Search',
                    onClick: (event, rows) => { },
                    isFreeAction: true,
                    type: 'search'
                },
                {
                    icon: 'create',
                    tooltip: 'Edit Service',
                    onClick: (event, row) => history.push(`/servizi/edit/${row.id}`),
                    position: "row"
                },
                {
                    icon: 'delete',
                    tooltip: 'Delete Student',
                    onClick: async (event, row) => {
                        showLoader();
                        await API.deleteRequest(`studentservices/${row.id}`);
                        await updateTable(`${pagBase}0`, 0);
                        hideLoader();
                    },
                    position: "row"
                },
                {
                    icon: 'add',
                    tooltip: 'New Service',
                    onClick: (event, rows) => newService(),
                    isFreeAction: true
                }
            ]}
            components={{
                Pagination: props => (
                    <TablePagination
                        {...props}
                        count={total}
                        page={currPage}
                        onChangePage={async (e, page) => {
                            showLoader();
                            await updateTable(pagBase + (page + 1), page);
                        }}
                    />
                ),
                Toolbar: props => (<div className={classes.flexWrap}><MTableToolbar {...props} /></div>),
                Action: props => {
                    switch (props.action.type) {
                        case 'actionButton':
                            return (
                                <Button
                                    onClick={(event) => props.action.onClick(event, props.data)}
                                    color="primary" variant="contained" style={{ margin: '10px 0 0 10px' }}
                                    size="small" >
                                    {props.action.icon}
                                </Button>
                            );
                        case 'search':
                            return (
                                <TextField
                                    id='tableSearch' onChange={e => searchFn(`${pagBase}0&partner_name=${e.target.value}`, 0)} placeholder='Search' defaultValue={search}
                                    InputProps={{ startAdornment: (<InputAdornment position="start"><Icon>{props.action.icon}</Icon></InputAdornment>) }}
                                />
                            );
                        default:
                            return (
                                <IconButton onClick={(event) => props.action.onClick(event, props.data)}>
                                    <Icon>{props.action.icon}</Icon>
                                </IconButton>
                            )
                    }
                }
            }}
        />
    )
}

export default Services;