import React, { useState, useEffect } from 'react';
import * as API from '../../../app/API';
import { TextField, CircularProgress } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { isObject } from 'lodash';

// Custom props: isSearch -> search endpoint , isGeoNames -> geonames api

export default function MuiAsyncAutocomplete(props) {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [val, setVal] = useState(null);
    const loading = open && options?.length === 0;
    const endpoint = props.endpoint;
    const isSearch = props.isSearch ? true : false;
    const isGeoNames = props.isGeoNames ? true : false;
    console.log(val);
    // Functions
    const getData = async (endpoint) => {
        if (endpoint) {
            // console.log(endpoint)
            if (!isSearch && !loading) return undefined;
            let res = await API.getRequest(`${endpoint}${endpoint.includes('?') ? '&' : '?'}ordtb=id&ordtype=desc`);
            if (!res) res = [{ name: 'empty' }]
            if (res.data) res = res.data;
            if (isObject(res)) res = Object.values(res);
            setOptions(res);
            return res;
        }
        return []
    }
    const geoResponse = async (search) => {
        let response = await fetch(encodeURI(`https://secure.geonames.org/search?name_startsWith=${search}&cities=cities1000&style=long&maxRows=10&type=json&username=dotflorence`))
        if (!response.ok) return response.status;
        response = await response.json();
        let georesponse = response.geonames;
        if (!georesponse.length && setOpen(false)) return;
        // console.log(georesponse);
        let geodata = georesponse.map(x => ({ ...x, name: `${x.name}, ${x.adminName1}, ${x.countryName}` }));
        setOptions(geodata);
    }
    // Hooks
    useEffect(() => {
        if (!isSearch) (async () => await getData(endpoint))();
        if (!open) setOptions([]);
        // eslint-disable-next-line
    }, [isSearch, open]);
    useEffect(() => {
        console.log(options)
        // eslint-disable-next-line
    }, [options]);

    return (
        <div className={props.className}>
            <Autocomplete
                id={props.id}
                open={open}
                autoHighlight
                value={props.value}
                onOpen={async () => {
                    setOpen(true);
                    if (isSearch) await getData(`search/${endpoint}/a`);
                    if (isGeoNames) await geoResponse("a");
                }}
                onClose={() => setOpen(false)}
                onChange={(e, value) => { if (props.onChange) props.onChange(props.id, value) }}
                getOptionSelected={(option, value) => value && !isSearch && !isGeoNames ? option.name === value.name : 'undefined'}
                // getOptionLabel={(option) => option.name ? option.name : option}
                getOptionLabel={option => {
                    if (endpoint === 'studentservices' && option?.partner?.name) return `${option?.partner?.name} - ${option.name}`;
                    return option.name ? option.name : option
                }}
                options={options}
                loading={loading}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        name={props.name}
                        label={props.label}
                        style={props.style}
                        required={props.required}
                        error={props.error}
                        helperText={props.helperText}
                        variant="outlined"
                        // onChange={async (e) => {
                        //     setVal(e.target.value);
                        //     setOptions([]);
                        //     if (isSearch) await getData(`search/${endpoint}/${e.target.value}`)
                        //     else if (isGeoNames) await geoResponse(e.target.value)
                        //     else await getData(`${endpoint}?name=${e.target.value}`);
                        // }}
                        onChange={async (e) => {
                            if (e.target.value) {
                                setVal(e.target.value);
                                setOptions([]);
                                if (isSearch) {
                                    await getData(`search/${endpoint}/${e.target.value}`);
                                } else if (isGeoNames) {
                                    await geoResponse(e.target.value);
                                } else {
                                    if (endpoint === 'studentservices') {
                                        const data = await getData(`${endpoint}?partner_name=${e.target.value}`);
                                        !data?.length && val && await getData(`${endpoint}?name=${val}`)
                                    }
                                    else await getData(`${endpoint}?name=${e.target.value}`);
                                }
                            }
                        }}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
            />
        </div>
    );
}

// const onChangeCustomField = (field, value) => { setFormFields({ ...formFields, [field]: value })}