import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import App from './App';
import store from './app/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();
// Disable console.log in production
if (process.env.NODE_ENV !== "development") console.log = () => {};

ReactDOM.render(
    <Provider store={store}>
      <Router history={history}>
        <App />
      </Router>
    </Provider>,
  document.getElementById('root')
);

// SW: on -> register(), off -> unregister()
serviceWorker.unregister();
