import React, { useState, useEffect, useCallback } from 'react';
import { makeGlobalStyles } from '../../styles/Styles';
import MaterialTable from 'material-table';
import { TablePagination, Switch } from '@material-ui/core';
import * as API from '../../app/API';
import { hideLoader, showLoader, editNumber, editSwitch } from '../../app/globals';

function Parts() {
    const pagBase = 'studentservicepartconcepts?page=';
    const classes = makeGlobalStyles();
    // State
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    const [needed, setNeeded] = useState(false);
    const [total, setTotal] = useState(0);
    const [currPage, setCurrPage] = useState(0);
    const [parttypes, setParttypes] = useState(null);
    const [mailfrom, setMailfrom] = useState(null);
    const [mailtemplates, setMailtemplates] = useState(null);
    const [lookupsLoaded, setLookupsLoaded] = useState(false);
    // Functions 

    const updateTable = useCallback(async (endpoint, page) => {
        let parts = await API.getRequest(endpoint);
        setTotal(parts?.meta?.total);
        parts = parts?.data;
        Object.values(parts).map(part => { for (var prop in part) { if (part[prop] === null) part[prop] = undefined }; return part }); // null to undefined
        // console.log(parts);
        setCurrPage(page);
        if (parts && parts.length) setData(parts);
    }, [])
    // Hooks
    useEffect(() => {
        (async () => {
            setParttypes((await API.getRequest('studentservicepartconcepttypes'))?.data?.reduce((acc, cur, i) => { acc[cur.id] = cur.name; return acc }, {}));
            setMailfrom((await API.getRequest('users'))?.data?.reduce((acc, cur, i) => { acc[cur.email] = cur.email; return acc }, {}));
            setMailtemplates(Object.values(await API.getRequest('studentmailtemplates'))?.reduce((acc, cur, i) => { acc[cur] = cur; return acc }, {}));
            setNeeded(true);
            setLookupsLoaded(true);
        })();
        return () => showLoader(); // componentWillUnmount
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        console.log(mailfrom);
        if (lookupsLoaded)
            setColumns([
                { title: "Name *", field: "name" },
                { title: "Type *", field: "type.id", lookup: parttypes },
                { title: "Default Tax *", field: "default_tax", editComponent: row => editNumber(row) },
                { title: "Default Value *", field: "default_value", editComponent: row => editNumber(row) },
                { title: "Default Optional", field: "default_optional", render: row => <Switch checked={row.default_optional ? true : false} />, editComponent: row => editSwitch(row) },
                { title: "Mail From", field: "mail_from", lookup: { "": null, ...mailfrom } },
                { title: "Mail Template", field: "mail_template", lookup: { "": null, ...mailtemplates } },
                { title: "Tag", field: "tag" }
            ]);
        // eslint-disable-next-line
    }, [lookupsLoaded])
    useEffect(() => {
        (async () => {
            await updateTable(`${pagBase}0`, 0);
            if (needed) hideLoader();
        })()
    }, [needed, updateTable])

    return (
        <MaterialTable
            title="Part Types"
            className={classes.materialTable}
            columns={columns}
            data={data}
            options={{ pageSize: 30, pageSizeOptions: [30] }}
            localization={{ body: { editRow: { deleteText: 'Are you sure to delete the Part?' } } }}
            editable={{
                onRowAdd: async (newData) => {
                    newData.type_id = newData.type?.id;
                    newData.default_optional = newData.default_optional ? 1 : 0;
                    delete newData.type;
                    // console.log(newData);
                    await API.postRequest(`studentservicepartconcepts`, newData);
                    await updateTable(`${pagBase}${currPage}`, currPage);
                    return;
                },
                onRowUpdate: async (newData, oldData) => {
                    newData.type_id = newData.type?.id;
                    delete newData.type;
                    // console.log(newData, oldData);
                    await API.patchRequest(`studentservicepartconcepts/${newData.id}`, newData);
                    await updateTable(`${pagBase}${currPage}`, currPage);
                    return;
                },
                onRowDelete: async (oldData) => {
                    // console.log(oldData);
                    await API.deleteRequest(`studentservicepartconcepts/${oldData.id}`);
                    await updateTable(`${pagBase}${currPage}`, currPage);
                    return;
                }
            }}
            components={{
                Pagination: props => (
                    <TablePagination
                        {...props}
                        count={total}
                        page={currPage}
                        onChangePage={async (e, page) => { showLoader(); await updateTable(pagBase + (page + 1), page) }}
                        className='test'
                    />
                )
            }}
        />
    )
}

export default Parts;