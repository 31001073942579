import React, { useEffect, Suspense } from 'react';
import './styles/App.css';
import { useSelector, useDispatch } from 'react-redux';
import { checkRoute } from './app/globals';
import appRoutes from './app/routes';
import { muiTheme, makeGlobalStyles } from "./styles/Styles";
import { ThemeProvider } from '@material-ui/styles';
import { history } from "./index";
import NavTop from './components/NavTop';
import MainSidebar from './components/MainSidebar';
import MainContent from './components/MainContent';
import Loader from './components/Loader';
import clsx from 'clsx';
import { loggedIn } from './app/store/slices';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import Page404 from './components/utilities/NotFoundPage';

function App(props) {
  document.title = process.env.REACT_APP_TITLE;
  const dispatch = useDispatch();
  if (localStorage.access_token) dispatch(loggedIn());
  const isPublicRoute = useSelector(state => state.App.isPublic);
  const isLoggedIn = useSelector(state => state.Login.isLoggedIn);
  const classes = makeGlobalStyles();
  useEffect(() => { checkRoute(props.location) }, [props.location])

  return (
    <ThemeProvider theme={muiTheme}>
      <Suspense fallback={<Loader />}>
        <div className={clsx(isLoggedIn && classes.dFlex, classes.fullPage, classes.paperBg)}>
          {isLoggedIn && !isPublicRoute ? <><NavTop /><MainSidebar /></> : ``}
          <Switch>
            <Redirect from="/:url*(/+)" to={history.location.pathname.slice(0, -1)} />
            <Route exact path="/"><Redirect to={appRoutes[0].path.split(':')[0]} /></Route>
            {appRoutes.map(route => route.group
              ? route.children.map(child => <Route key={child.name} exact={child.isExact} path={child.path} render={() => routeRender(child, isLoggedIn)} />)
              : <Route key={route.name} exact={route.isExact} path={route.path} render={() => routeRender(route, isLoggedIn)} />
            )}
            <Route component={Page404} />
          </Switch>
        </div>
        <Loader />
      </Suspense>
    </ThemeProvider>
  );
}

function routeRender(route, isLoggedIn) {
  let RouteComponent = route.view;
  history.location.state = { ...history.location.state, isPublic: route.isPublic };
  return !route.isPublic ? (isLoggedIn ? <MainContent component={RouteComponent} /> : <Redirect to='/login' />) : <RouteComponent />
}

export default withRouter(App);