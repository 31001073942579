import React, { useEffect } from 'react';
import { makeGlobalStyles } from '../../../styles/Styles';
import { hideLoader, showLoader } from '../../../app/globals';
import { withRouter } from 'react-router-dom';

function Page404(props) {
    const classes = makeGlobalStyles();
    const pathname = props?.location?.pathname;
    const urlpath = pathname.length > 30 ? `${pathname.substring(0,30)}...` : pathname;

    // Hooks
    useEffect(() => {
        hideLoader();
        return () => showLoader(); 
    }, []);

    return (
        <div className={classes.page404}>
            <h1>404</h1>
            <h3>Url {urlpath ? <pre>{urlpath}</pre> : ``} not found</h3>
        </div>
    )
}

export default withRouter(Page404);