import React, { useState, useEffect, useCallback } from 'react';
import { history } from "../../index";
import { makeGlobalStyles } from '../../styles/Styles';
import MaterialTable, { MTableToolbar } from 'material-table';
import { TablePagination, Button, IconButton, Icon, TextField, InputAdornment, Paper, FormControlLabel, Switch, Box } from '@material-ui/core';
import * as API from '../../app/API';
import { hideLoader, showLoader, defProps } from '../../app/globals';
import MuiAsyncAutocomplete from '../utilities/MuiAsyncAutocomplete';
import { withRouter } from 'react-router-dom';

function Students(props) {
    const page = parseInt(props.match.params.page) || 0;
    const pagBase = `students?page=`;
    const rows = `&rows=`;
    const classes = makeGlobalStyles();
    // State
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [currPage, setCurrPage] = useState(0);
    const [perPage, setPerPage] = useState(30);
    const [order, setOrder] = useState("&ordtb=id&ordtype=desc");
    const [compactView, setCompactView] = useState(true);
    // Functions 
    const updateTable = async (endpoint, page) => {
        showLoader();
        let studentsdata = await API.getRequest(endpoint + order);
        let students = studentsdata.data;
        console.log(students);
        setTotal(studentsdata.meta.total);
        setCurrPage(parseInt(page));
        // if (students && students.length) {
        setColumns([
            { title: "Cognome", field: "last_name", render: rowData => <div data-status={rowData?.status?.id}>{rowData.last_name}</div> },
            { title: "Nome", field: "first_name" },
            { title: "Sesso", field: "gender" },
            { title: "Email", field: "email" },
            { title: "Created At", field: "created_at", render: rowData => <div>{rowData?.created_at}</div> },
            // { title: "Created At", field: "created_at", render: rowData => <div>{rowData?.created_at?.split(' ')[0]}</div> },
            { title: "Status", field: "status.name" },
            { title: "Payment", field: "order", render: rowData => <div>{rowData?.order?.processed ? 'COMPLETED' : 'NOT COMPLETED'}</div> },
            { title: "Servizio", field: "service.name" },
            { title: "Partner", field: "service.partner.name" }
        ]);
        setData(students);
        // }
        // Search
        setSearchName((new URLSearchParams(endpoint)).get("name"));
        highlightCompleted();
        hideLoader();
    }
    const newStudent = () => {
        showLoader();
        history.push("/studenti/new");
    }
    const downloadZip = async (rows, action, single = false, responseType = 'blob') => {
        let ids = action.includes("?ids=") ? null : rows.map(x => x.id);
        console.log(action, ids);
        window.location.assign(`${API.api + action}${ids ? '?ids=[' + ids.toString() + ']' : ''}`);
        // const blob = await API.getRequest(`${action}${single ? `/${ids[0]}` : `?ids=[${ids.toString()}]`}`, false, false, responseType);
        // if (blob) {
        //     const url = window.URL.createObjectURL(blob);
        //     const a = document.createElement('a');
        //     a.style.display = 'none';
        //     a.href = url;
        //     a.download = `${action}.zip`;
        //     document.body.appendChild(a);
        //     a.click();
        //     window.URL.revokeObjectURL(url);
        // }
    }
    // Search,
    const [searchName, setSearchName] = useState('');
    const [searchPartner, setSearchPartner] = useState('');
    const [searchService, setSearchService] = useState('');
    // eslint-disable-next-line
    const searchNameFn = useCallback(API.debounce(updateTable, 400), []);
    // Highlight completed students
    const highlightCompleted = () => {
        setTimeout(() => {
            Array.from(document.querySelectorAll('[data-status]')).map(x => {
                // console.log(x.dataset.status);
                return x.closest('tr').style.background = parseInt(x.dataset.status) === 2 ? "rgba(50, 168, 82, .3)" : "transparent";
            });
        }, 500);
    }
    // Hooks
    useEffect(() => {
        (async () => {
            await updateTable(pagBase + (page + 1) + rows + perPage, page);
            // highlightCompleted();
        })();
        return () => showLoader(); // componentWillUnmount
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        setData(data);
        // eslint-disable-next-line
    }, [perPage]);

    return (
        <>
            <FormControlLabel
                control={<Switch checked={compactView} onChange={() => { setCompactView(!compactView); highlightCompleted(); }} />}
                label="Compact View"
            />
            <MaterialTable
                title="Studenti"
                columns={columns}
                data={data}
                pageSize={perPage}
                options={{
                    search: false,
                    selection: true,
                    pageSize: 2000,
                    pageSizeOptions: [5, 15, 30, 60, 100, 500, 1000, 2000]
                }}
                actions={[
                    {
                        icon: 'create',
                        tooltip: 'Edit Student',
                        onClick: (event, row) => history.push(`/studenti/edit/${row.id}`),
                        position: "row"
                    },
                    {
                        icon: 'delete',
                        tooltip: 'Delete Student',
                        onClick: async (event, row) => {
                            const action = confirm("Confermare eliminazione?");
                            if (action === true) {
                                showLoader();
                                await API.deleteRequest(`students/${row.id}`);
                                await updateTable(pagBase + (page + 1) + rows + perPage, page);
                                // highlightCompleted();
                            }
                        },
                        position: "row"
                    },
                    {
                        icon: 'Elimina',
                        tooltip: 'Delete Student',
                        onClick: async (event, rows) => {
                            const ids = rows.map(x => x.id)
                            const action = confirm("Confermare eliminazione?");
                            if (action === true) {
                                showLoader();
                                for (const id of ids)
                                    await API.deleteRequest(`students/${id}`);
                                await updateTable(pagBase + (page + 1) + rows + perPage, page);
                                // highlightCompleted();
                            }
                        },
                        type: "actionButton"
                    },
                    {
                        icon: 'search',
                        tooltip: 'Name',
                        onClick: (event, rows) => { },
                        isFreeAction: true,
                        type: 'search'
                    },
                    {
                        icon: 'search',
                        tooltip: 'Partner',
                        onClick: (event, rows) => { },
                        isFreeAction: true,
                        type: 'partner'
                    },
                    {
                        icon: 'search',
                        tooltip: 'Service',
                        onClick: (event, rows) => { },
                        isFreeAction: true,
                        type: 'studentservices'
                    },
                    {
                        icon: 'add',
                        tooltip: 'New Student',
                        onClick: (event, rows) => newStudent(),
                        isFreeAction: true
                    },
                    {
                        icon: 'M209',
                        onClick: (event, rows) => downloadZip(rows, 'm209'),
                        type: 'actionButton'
                    },
                    {
                        icon: 'PSE',
                        onClick: (event, rows) => downloadZip(rows, 'pse'),
                        type: 'actionButton'
                    },
                    {
                        icon: 'FiscalCode',
                        onClick: (event, rows) => downloadZip(rows, 'fiscal'),
                        type: 'actionButton'
                    },
                    // {
                    // icon: 'InViola Card',
                    // onClick: (event, rows) => downloadZip(rows, 'inviola'),
                    // type: 'actionButton'
                    // icon: 'file_download',
                    // tooltip: 'DOP',
                    // onClick: (event, row) => window.open(`${API.api}dop/${row.id}`, "_blank"),
                    // position: "row"
                    // },
                    // {
                    //     icon: 'CSV Export',
                    //     onClick: (event, rows) => downloadZip(rows, 'export'),
                    //     type: 'actionButton'
                    // },
                    {
                        icon: 'Assegna N° Fatture',
                        onClick: async (event, rows) => {
                            showLoader();
                            await API.postRequest(`invoice?ids=[${rows.map(x => x?.order?.id)?.toString()}]`);
                            await updateTable(pagBase + (page + 1) + rows + perPage, page);
                            // highlightCompleted();
                        },
                        type: 'actionButton'
                    },
                    {
                        icon: 'Esporta Fatture',
                        onClick: (event, rows) => downloadZip(rows, `invoiceslistexport?ids=[${rows.map(x => x?.order?.id)?.toString()}]`),
                        type: 'actionButton'
                    }
                ]}
                components={{
                    Container: props => <Paper className={`${classes.materialTable} ${compactView ? classes.compactView : ''}`} {...props} />,
                    Pagination: props => (
                        <>
                            <TablePagination
                                {...props}
                                count={total}
                                page={currPage}
                                rowsPerPage={perPage}
                                onChangePage={async (e, page) => {
                                    showLoader();
                                    let endpoint = `${pagBase}${page + 1}${rows}${perPage}&name=${searchName || ''}&partner_id=${searchPartner?.id || ''}&service_id=${searchService?.id || ''}`;
                                    await updateTable(endpoint, page);
                                    // highlightCompleted();
                                    history.push(`/studenti/${page}`);
                                }}
                                onChangeRowsPerPage={async (event) => {
                                    let rowsPerPage = event.target.value;
                                    // console.log(rowsPerPage);
                                    setPerPage(rowsPerPage);
                                    showLoader();
                                    let endpoint = `${pagBase}0${rows}${rowsPerPage}&name=${searchName || ''}&partner_id=${searchPartner?.id || ''}&service_id=${searchService?.id || ''}`;
                                    await updateTable(endpoint, 0);
                                    // highlightCompleted();
                                    history.push(`/studenti/0`);
                                }}
                            />
                        </>
                    ),
                    Toolbar: props => (<div className={`${classes.flexWrap} ${classes.tableToolbar}`}><MTableToolbar {...props} /></div>),
                    Action: props => {
                        switch (props.action.type) {
                            case 'actionButton':
                                return (
                                    <Button
                                        onClick={(event) => props.action.onClick(event, props.data)}
                                        color="primary" variant="contained" style={{ margin: '10px 0 0 10px' }}
                                        size="small" >
                                        {props.action.icon}
                                    </Button>
                                );
                            case 'search':
                                return (
                                    <TextField
                                        id='tableSearchName' placeholder='Search' defaultValue={searchName}
                                        InputProps={{ startAdornment: (<InputAdornment position="start"><Icon>{props.action.icon}</Icon></InputAdornment>) }}
                                        onChange={e => {
                                            searchNameFn(`${pagBase}0&name=${e.target.value || ''}&partner_id=${searchPartner?.id || ''}&service_id=${searchService?.id || ''}&rows=${perPage}`, 0);
                                            highlightCompleted();
                                        }}
                                    />
                                );
                            case 'partner':
                                return (
                                    <Box style={{ marginLeft: '20px' }}>
                                        <MuiAsyncAutocomplete {...defProps} value={searchPartner || ''} style={{ minWidth: '200px' }}
                                            endpoint="partners" label='Partner'
                                            onChange={async (field, value) => {
                                                setSearchPartner(value);
                                                await updateTable(`${pagBase}0&name=${searchName || ''}&partner_id=${value?.id || ''}&service_id=${searchService?.id || ''}&rows=${perPage}`, 0);
                                                // highlightCompleted();
                                            }}
                                        />
                                    </Box>
                                );
                            case 'studentservices':
                                return (
                                    <Box style={{ marginLeft: '20px' }}>
                                        <MuiAsyncAutocomplete {...defProps} value={searchService || ''} style={{ minWidth: '200px' }}
                                            endpoint="studentservices" label='Service'
                                            onChange={async (field, value) => {
                                                setSearchService(value);
                                                await updateTable(`${pagBase}0&name=${searchName || ''}&partner_id=${searchPartner?.id || ''}&service_id=${value?.id || ''}&rows=${perPage}`, 0);
                                                // highlightCompleted();
                                            }}
                                        />
                                    </Box>
                                );
                            default:
                                return (
                                    <IconButton onClick={(event) => props.action.onClick(event, props.data)} style={{ margin: '20px' }}>
                                        <Icon>{props.action.icon}</Icon>
                                    </IconButton>
                                )
                        }
                    }
                }}
            />
        </>
    )
}

export default withRouter(Students);