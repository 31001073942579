import React from 'react';
import { useSelector } from 'react-redux';
import { makeGlobalStyles } from '../../styles/Styles';
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';

function Loader() {
    const loading = useSelector(state => state.Loader.loading);
    const classes = makeGlobalStyles();
    return (
        <div className={clsx({
            [classes.loader]: true,
            [classes.dnone]: !loading
        })}>
            <CircularProgress style={{ height: '90px', width: '90px' }} />
        </div>
    )
}

export default Loader;