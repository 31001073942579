/**
 * GEONAMES
 * https://www.geonames.org/login           => new account
 * https://www.geonames.org/manageaccount   => enable free web service
 * marmots - ??? - ???
 * italy4you1 - G30n4m3sP4$$ - tommaso.meli@dotflorence.com
*/

import React, { useState, useEffect } from 'react';
import * as API from '../../../app/API';
import { TextField, CircularProgress } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { isObject } from 'lodash';
import { Controller } from "react-hook-form";

// Custom props: isSearch -> search endpoint , isGeoNames -> geonames api
export default function AsyncAutocomplete(props) {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [val, setVal] = useState(null);
    const loading = open && options?.length === 0;
    const { endpoint, control, error, helperText, rules, name, label } = props;
    const isSearch = props.isSearch ? true : false;
    const isGeoNames = props.isGeoNames ? true : false;
    const inputProps = ({ isSearch, isGeoNames, defaultValue, ...rest }) => rest;
    console.log(val);
    // Functions
    const getData = async (endpoint) => {
        if (endpoint) {
            if (!isSearch && !loading) return undefined;
            let res = await API.getRequest(endpoint);
            if (!res) res = [{ name: 'empty' }]
            if (res.data) res = res.data;
            if (isObject(res)) res = Object.values(res);
            // console.log(res);
            // res.unshift({ name: 'None' })
            setOptions(res);
        }
    }
    const geoResponse = async (search) => {
        if (search) {
            let response = await fetch(encodeURI(`https://secure.geonames.net/search?name_startsWith=${search}&cities=cities1000&style=long&maxRows=10&type=json&username=italy4you1`))
            if (!response.ok) return response.status;
            response = await response.json();
            let georesponse = response.geonames;
            if (!georesponse.length && setOpen(false)) return;
            let geodata = georesponse.map(x => ({ ...x, name: `${x.name}, ${x.adminName1}, ${x.countryName}` }));
            // console.log(geodata);
            // geodata.unshift({ name: 'None' })
            setOptions(geodata);
        }
    }
    // Hooks
    useEffect(() => {
        if (!isSearch) (async () => await getData(endpoint))();
        if (!open) setOptions([]);
        // eslint-disable-next-line
    }, [isSearch, open]);
    // }, [isSearch, open, val]);

    return (
        <Controller
            name={name} control={control} rules={rules} defaultValue=""
            render={({ value }) =>
                <Autocomplete
                    value={value}
                    open={open} autoHighlight options={options} loading={loading}
                    onOpen={async () => {
                        setOpen(true);
                        if (isSearch) await getData(`search/${endpoint}/a`);
                        if (isGeoNames) await geoResponse("a");
                    }}
                    onClose={() => setOpen(false)}
                    onChange={(e, value) => { /* console.log(value); */ setVal(value); control.setValue(props.name, value); control.trigger(props.name) }}
                    getOptionSelected={(option, value) => value && !isSearch && !isGeoNames ? option.name === value.name : 'undefined'}
                    getOptionLabel={option => {
                        if (endpoint === 'studentservices' && option?.partner?.name) return `${option?.partner?.name} - ${option.name}`;
                        return option.name ? option.name : option
                    }}
                    renderInput={params => (
                        <TextField
                            {...{ ...params, ...inputProps(props) }}
                            required={props.required ? true : false}
                            label={label} error={error} helperText={helperText} variant="outlined"
                            onChange={async (e) => {
                                if (e.target.value) {
                                    setOptions([]);
                                    if (isSearch) {
                                        await getData(`search/${endpoint}/${e.target.value}`);
                                    } else if (isGeoNames) {
                                        await geoResponse(e.target.value);
                                    } else {
                                        endpoint === 'studentservices'
                                            ? await getData(`${endpoint}?partner_name=${e.target.value}`)
                                            : await getData(`${endpoint}?name=${e.target.value}`);
                                    }
                                }
                            }}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: <>{loading ? <CircularProgress color="inherit" size={20} /> : null}{params.InputProps.endAdornment}</>
                            }}
                        />
                    )}
                />}
        />
    );
}