import React, { useEffect, useState } from 'react';
import { hideLoader, showLoader } from '../../../app/globals';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSet } from '../../../app/globals';
import * as API from '../../../app/API';
import "../styles.css";

function PaymentError(props) {
    const queryParams = new URLSearchParams(props.location.search),
        student_id = queryParams.get('student_id')
    // state
    const [student, setStudent] = useState(null);
    console.log(student, student_id);

    useEffect(() => {
        showLoader();
        if (student_id) {
            (async () => {
                let data = await API.getRequest(`students/${student_id}`);
                console.log(data);
                if (data) setStudent(data);
                hideLoader();
            })();
        } else hideLoader();
        // eslint-disable-next-line
    }, []);

    return (
        <div className="stripeWrapper">
            <FontAwesomeIcon icon={faSet.faCcStripe} className="fa-xl" />
            <h1><strong>Error</strong></h1>
            <h2>ID: {student_id}</h2>
        </div>
    )
}

export default withRouter(PaymentError);