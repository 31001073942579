import React, { useState, useEffect } from 'react';
import { makeGlobalStyles } from '../../../styles/Styles';
import MaterialTable from 'material-table';
import { TablePagination } from '@material-ui/core';
import * as API from '../../../app/API';
import { hideLoader, showLoader } from '../../../app/globals';
import moment from 'moment';

function PartsTypes() {
    const pagBase = 'studentservicepartconcepttypes?page=';
    const classes = makeGlobalStyles();
    // State
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [currPage, setCurrPage] = useState(0);
    // Functions 
    const updateTable = async (endpoint, page) => {
        let partTypesdata = await API.getRequest(endpoint);
        let partTypes = partTypesdata.data;
        // console.log(partTypes); 
        setTotal(partTypesdata.meta.total);
        setCurrPage(page);
        if (partTypes && partTypes.length) setData(partTypes);
        hideLoader();
    }
    // Hooks
    useEffect(() => {
        (async () => {
            await updateTable(`${pagBase}0`, 0);
            setColumns([
                { title: "Nome", field: "name" },
                { title: "Last mod", field: "updated_at", editable: 'never' }
            ]);
        })();
        return () => showLoader(); // componentWillUnmount
    }, []);

    return (
        <MaterialTable
            title="Part Types"
            className={classes.materialTable}
            columns={columns}
            data={data}
            options={{ search: false, pageSize: 30, pageSizeOptions: [30] }}
            localization={{ body: { editRow: { deleteText: 'Are you sure to delete the Part Type?' } } }}
            editable={{
                onRowAdd: async (newData) => {
                    // console.log(newData);
                    newData.updated_at = moment().format("yyy-MM-D, hh:mm:ss");
                    await API.postRequest(`studentservicepartconcepttypes`, newData);
                    await updateTable(`${pagBase}${currPage}`, currPage);
                    return;
                },
                onRowUpdate: async (newData, oldData) => {
                    // console.log(newData, oldData);
                    newData.updated_at = moment().format("yyy-MM-D, hh:mm:ss");
                    await API.patchRequest(`studentservicepartconcepttypes/${newData.id}`, newData);
                    await updateTable(`${pagBase}${currPage}`, currPage);
                    return;
                },
                onRowDelete: async (oldData) => {
                    // console.log(oldData);
                    await API.deleteRequest(`studentservicepartconcepttypes/${oldData.id}`);
                    await updateTable(`${pagBase}${currPage}`, currPage);
                    return;
                }
            }}
            components={{
                Pagination: props => (
                    <TablePagination
                        {...props}
                        count={total}
                        page={currPage}
                        onChangePage={async (e, page) => { showLoader(); await updateTable(pagBase + (page + 1), page) }}
                    />
                )
            }}
        />
    )
}

export default PartsTypes;