import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setRouteIndex } from '../../app/store/slices';
import { makeGlobalStyles } from '../../styles/Styles';
import clsx from 'clsx';

function MainContent(props) {
    const sidebarState = useSelector(state => state.MainSidebar.open);
    const classes = makeGlobalStyles();
    const MainComponent = props.component;
    const { index } = props;
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setRouteIndex(index));
    }, [dispatch, index])

    return (
        <div className={`${classes.w_100} ${classes.mainContent}`}>
            <main className={clsx(classes.content, { [classes.contentShift]: sidebarState })} >
                <div className={classes.drawerHeader} />
                <MainComponent />
            </main>
        </div>
    )
}

export default MainContent;