import React, { useState, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { sleep } from '../../../app/globals';
import { makeGlobalStyles } from '../../../styles/Styles';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

export default function Notify(props) {
    const classes = makeGlobalStyles();
    // State
    const [open, setOpen] = useState(true);
    // Functions
    const closeNotify = async () => {
        setOpen(false);
        let container = document.getElementById('notify');
        if (container) {
            await sleep(300);
            container.remove();
        }
    }
    return (
        <>
            <Snackbar
                className={classes.notifySnackbar}
                anchorOrigin={props.position}
                open={open}
                autoHideDuration={4000}
                onClose={closeNotify}
                message={props.message}
                action={
                    <Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={closeNotify}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </Fragment>
                }
            />
        </>
    )
}

export const makeNotify = (message, position = { vertical: 'top', horizontal: 'right' }) => {
    let container = document.createElement('div');
    container.id = "notify";
    document.body.append(container);
    ReactDOM.render(
        <Notify message={message} position={position} />,
        document.getElementById('notify')
    );
    return true;
}