import React, { useState, useEffect } from 'react';
import { makeGlobalStyles } from '../../styles/Styles';
import { hideLoader, showLoader, fieldError, defProps } from '../../app/globals';
import { useForm } from 'react-hook-form';
import * as API from '../../app/API';
import MuiDatePicker from '../utilities/MuiDatePicker';
import { Button, Container } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { makeNotify } from '../utilities/Notify';

function BulkInvoices() {
    const classes = makeGlobalStyles();
    const { register, handleSubmit, watch, control, errors } = useForm({ shouldUnregister: false });
    const watchFields = watch(["date_from", "date_to"]);
    // State
    const [isExport, setIsExport] = useState(false);
    const [fieldsCount, setFieldsCount] = useState(null);
    const [disabled, setIsDisabled] = useState(true);
    // Functions
    const formatDate = date => {
        const D = new Date(date);
        return [D.getFullYear(), D.getMonth() + 1, D.getDate()].join("/");
    }
    const onSubmit = async (data) => {
        showLoader();
        // console.log(data);
        const parsedDate = { date_from: formatDate(data.date_from), date_to: formatDate(data.date_to) };
        if (Object.values(parsedDate).filter(Boolean).length) {
            let exportUrl = `${API.api}invoiceslistexport/?${new URLSearchParams(parsedDate).toString()}`;
            console.log(exportUrl);
            let res = isExport
                ? await (async () => {
                    window.location.assign(exportUrl)
                    return exportUrl;
                })()
                // ? await API.getRequest(`invoiceslistexport`, parsedDate)
                : await API.postRequest(`invoice`, parsedDate)
            console.log(res);
            if (res?.error) makeNotify(res.error)
            else makeNotify(isExport ? "Invoices exported." : "Invoices generated.")
        } else makeNotify("Dates not provided.")
        hideLoader();
    }
    // Hooks
    useEffect(() => {
        hideLoader();
        return () => showLoader();
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        const count = Object.values(watchFields).filter(Boolean).length;
        if (count !== fieldsCount) {
            setFieldsCount(count);
            if (count === 2 && disabled === true) setIsDisabled(false)
            else if (disabled === false) setIsDisabled(true)
        }
        // eslint-disable-next-line
    }, [watchFields]);

    return (
        <Container maxWidth="sm" style={{ marginLeft: 0 }}>
            <form id="studentForm" onSubmit={handleSubmit(onSubmit)} autoComplete='off' className={classes.accountForm}>
                <MuiDatePicker {...defProps} id="date_from" name="date_from" label="Date From" control={control} format="yyy-MM-dd" {...fieldError('date_from', errors)} />
                <MuiDatePicker {...defProps} id="date_to" name="date_to" label="Date To" control={control} format="yyy-MM-dd" {...fieldError('date_to', errors)} />
                <input type="hidden" ref={register} name="isExport" id="isExport" value={isExport} />
                <Button type="submit" disabled={disabled} variant="contained" color="primary" size="medium" startIcon={<SaveIcon />} style={{ margin: "10px" }} onClick={() => setIsExport(false)}>
                    Generate
                </Button>
                <Button type="submit" disabled={disabled} variant="contained" color="primary" size="medium" startIcon={<SaveIcon />} style={{ margin: "10px" }} onClick={() => setIsExport(true)}>
                    Export
                </Button>
            </form>
        </Container>
    )
}

export default BulkInvoices;