// import Events from "../components/Events";
import Students from "../components/Students";
import StudentEdit from "../components/Students/StudentEdit";
import StudentForm from "../components/Students/StudentForm";
import Partners from "../components/Partners";
import PartnerEdit from "../components/Partners/PartnerEdit";
import Services from "../components/Services";
import ServiceEdit from "../components/Services/ServiceEdit";
import Parts from "../components/Parts";
import PartsTypes from "../components/Parts/PartsTypes";
// import Users from "../components/Users";
import Login from "../components/Login";
// import Stripe from "../components/Stripe";
import PaymentSuccess from "../components/Payment/Success";
import PaymentError from "../components/Payment/Error";
import { faSet } from './globals'
import BulkInvoices from "../components/BulkInvoices";

// App routes
const appRoutes = [
    // {
    //     name: 'Eventi',                 // Label
    //     path: '/eventi',                // Url
    //     view: Events,                   // Component to render
    //     isExact: true,                  // is exact url (bool)
    //     visibility: ['sidebar'],        // Route access (default: empty array)
    //     group: '',                      // group of routes
    //     children: []                    // group children
    //     icon: faSet.faCalendarAlt       // Module icon
    //     isPublic: false                 // is public (bool)
    // },
    {
        name: 'Studenti',
        path: '/studenti/:page([0-9]*)?',
        view: Students,
        isExact: true,
        visibility: ['sidebar'],
        icon: faSet.faUserGraduate,
        isPublic: false
    },
    {
        name: 'Studente',
        path: '/studenti/:action(new|edit)/:id?',
        view: StudentEdit,
        isExact: true,
        isPublic: false
    },
    {
        name: 'Form Studente',
        path: '/register/:partner/:link',
        view: StudentForm,
        isExact: true,
        visibility: ['public'],
        icon: faSet.faBookUser,
        isPublic: true
    },
    {
        name: 'Partners',
        path: '/partners',
        view: Partners,
        isExact: true,
        visibility: ['sidebar'],
        icon: faSet.faHandshakeAlt,
        isPublic: false
    },
    {
        name: 'Partner',
        path: '/partners/:action(new|edit)/:id?',
        view: PartnerEdit,
        isExact: true,
        isPublic: false
    },
    {
        name: 'Services',
        path: '/servizi',
        view: Services,
        isExact: true,
        visibility: ['sidebar'],
        icon: faSet.faCogs,
        isPublic: false
    },
    {
        name: 'Service',
        path: '/servizi/:action(new|edit)/:id?',
        view: ServiceEdit,
        isExact: true,
        isPublic: false
    },
    {
        name: 'Bulk Invoices',
        path: '/invoices',
        view: BulkInvoices,
        isExact: true,
        visibility: ['sidebar'],
        icon: faSet.faReceipt,
        isPublic: false
    },
    {
        name: 'Parts',
        group: 'Parts',
        children: [
            { name: 'Parts List', path: '/parts-list', view: Parts, isExact: true, isPublic: false, parentId: 'Parts' },
            { name: 'Parts Types', path: '/parts-types', view: PartsTypes, isExact: true, isPublic: false, parentId: 'Parts' }
        ],
        visibility: ['sidebar'],
        icon: faSet.faFileInvoice
    },
    // {
    //     name: 'Utenti',
    //     path: '/utenti',
    //     view: Users,
    //     isExact: true,
    //     visibility: ['sidebar'],
    //     icon: faSet.faUsers,
    //     isPublic: false            
    // },
    // {
    //     name: 'Stripe',
    //     path: '/payment/:studentId([0-9]*)',
    //     view: Stripe,
    //     isExact: true,
    //     isPublic: true
    // },
    {
        name: 'Stripe',
        path: '/payment/success',
        view: PaymentSuccess,
        isExact: true,
        isPublic: true
    },
    {
        name: 'Stripe',
        path: '/payment/error',
        view: PaymentError,
        isExact: true,
        isPublic: true
    },
    {
        name: 'Login',
        path: '/login',
        view: Login,
        isExact: true,
        isPublic: true
    },
]

export default appRoutes;