
// console.log(process.env.NODE_ENV);
export const api = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
  ? process.env.REACT_APP_API_DEV
  : process.env.REACT_APP_API_PROD;

export const headers = () => {
  return {
    'Accept': 'application/json',
    'Authorization': `Bearer ${localStorage.access_token}`,
    'Content-Type': 'application/json'
  }
};

export const debounce = (func, wait) => {
  let timeout;
  return function (...args) {
    const context = this;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };
}

// General Fetch Request
export const doRequest = async function (method, endpoint, data = false, formData = false, dataToParams = false, responseType = "", hds = headers()) {
  let body = dataToParams ? null : (formData ? data : JSON.stringify(data));
  let params = data && dataToParams ? `?${new URLSearchParams(data).toString()}` : '';
  let response = await fetch(`${api}${endpoint}${params}`, { headers: hds, method: method, body: body, responseType });
  let info = method !== 'GET' ? { ok: response.ok, status: response.status } : null;
  try { response = responseType === "blob" ? await response.blob() : { ...await response.json(), ...info } }
  finally {
    // console.log(response);
    return response;
  }
}

// GET Request
export const getRequest =
  async (endpoint, data, formData, responseType, hds) => await doRequest("GET", endpoint, data, formData, true, responseType, hds);

// POST Request
export const postRequest =
  async (endpoint, data, formData, dataToParams, responseType, hds) => await doRequest("POST", endpoint, data, formData, dataToParams, responseType, hds);

// PATCH Request
export const patchRequest =
  async (endpoint, data, formData, dataToParams, responseType, hds) => await doRequest("PATCH", endpoint, data, formData, dataToParams, responseType, hds);

// DELETE Request
export const deleteRequest = async (endpoint) => await doRequest("DELETE", endpoint);