import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { history } from "../../index";
import * as API from "../API";
import { makeNotify } from '../../components/utilities/Notify';

/**
 * LOGIN SLICE
*/

// async thunks 
export const loginAction = createAsyncThunk("login/loginAction", (credentials, thunkApi) => {
    thunkApi.dispatch(loaderShow());
    return fetch(encodeURI(`${API.api}login?email=${credentials.email}&password=${credentials.password}`), { method: 'POST' })
        .then(response => {
            if (!response.ok) {
                thunkApi.dispatch(loaderHide());
                makeNotify("Credentials Error. Try again");
                // throw new Error(response.status);
                return;
            }
            return response.json();
        })
        .then(json => {
            localStorage.setItem("access_token", json.access_token);
            history.push('/');
            thunkApi.dispatch(loggedIn());
            return json;
        });
})

// Slice
export const LoginSlice = createSlice({
    name: 'login',
    initialState: {
        isLoggedIn: false,
        isLoggingIn: false,
        error: "",
        data: []
    },
    reducers: {
        loggedIn: state => {
            state.isLoggedIn = true;
            state.isLoggingIn = false;
        },
        loggedOut: state => {
            state.isLoggedIn = false;
            state.isLoggingIn = false;
        }
    },
    extraReducers: {
        [loginAction.pending]: state => { // login start
            state.isLoggedIn = false;
            state.isLoggingIn = true;
        },
        [loginAction.rejected]: (state, action) => { // login fail
            state.isLoggedIn = false;
            state.isLoggingIn = false;
            state.error = state.error.concat(action.payload);
        },
        [loginAction.fulfilled]: (state, action) => { // login 
            state.data = state.data.concat(action.payload);
        },

    }
});

export const { loggedIn, loggedOut } = LoginSlice.actions; // actions
export const LoginReducer = LoginSlice.reducer; // reducer

/**
 * MAIN SIDEBAR SLICE
*/

export const MainSidebarSlice = createSlice({
    name: 'mainsidebar',
    initialState: {
        open: true
    },
    reducers: {
        sidebarToggle: state => {
            state.open = !state.open;
        },
        sidebarOpen: state => {
            state.open = true;
        },
        sidebarClose: state => {
            state.open = false;
        }
    }
});

export const { sidebarToggle, sidebarOpen, sidebarClose } = MainSidebarSlice.actions; // actions
export const MainSidebarReducer = MainSidebarSlice.reducer; // reducer

/**
 * LOADER SLICE
*/

export const LoaderSlice = createSlice({
    name: 'loader',
    initialState: {
        loading: true
    },
    reducers: {
        loaderToggle: state => {
            state.loading = !state.loading;
        },
        loaderShow: state => {
            state.loading = true;
        },
        loaderHide: state => {
            state.loading = false;
        }
    }
});

export const { loaderToggle, loaderShow, loaderHide } = LoaderSlice.actions; // actions
export const LoaderReducer = LoaderSlice.reducer; // reducer

/**
 * APP SLICE
*/

export const AppSlice = createSlice({
    name: 'App',
    initialState: {
        isPublic: false,
        routeIndex: 0,
        userData: {}
    },
    reducers: {
        publicRoute: state => {
            state.isPublic = true;
        },
        privateRoute: state => {
            state.isPublic = false;
        },
        setRouteIndex: (state, action) => {
            state.routeIndex = action.payload;
        },
        setUserData: (state, action) => {
            state.userData = action.payload;
        },
        setStepOk: (state, action) => {
            state.stepOk = action.payload;
        }
    }
});

export const { publicRoute, privateRoute, setRouteIndex, setUserData } = AppSlice.actions; // actions
export const AppReducer = AppSlice.reducer; // reducer

/**
 * STEPPER SLICE
*/

export const StepperSlice = createSlice({
    name: 'stepper',
    initialState: {
        stepOk: false,
        stepAction: undefined,
        externalManagement: false
    },
    reducers: {
        setStepOk: (state, action) => {
            state.stepOk = action.payload;
        },
        setStepAction: (state, action) => {
            state.stepAction = action.payload;
        },
        setExternalManagement: (state, action) => {
            state.externalManagement = action.payload;
        }
    }
});

export const { setStepOk, setStepAction, setExternalManagement } = StepperSlice.actions; // actions
export const StepperReducer = StepperSlice.reducer; // reducer