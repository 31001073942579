import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeGlobalStyles } from '../../styles/Styles';
import { sidebarToggle } from '../../app/store/slices';
import { logout, favicon } from "../../app/globals";
import clsx from 'clsx';
import { faSet } from '../../app/globals';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTheme, Button, IconButton, Typography, Toolbar, AppBar, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

function NavTop() {
    const sidebarState = useSelector(state => state.MainSidebar.open);
    const dispatch = useDispatch();
    const classes = makeGlobalStyles();
    const theme = useTheme();
    // State
    const [doLogout, setDoLogout] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    // Actions
    const handleLogout = () => logout();
    const toggleSidebar = () => dispatch(sidebarToggle());
    // Hooks
    useEffect(() => {
        if (doLogout) handleLogout();
    });

    return (
        <AppBar position="fixed" className={clsx(classes.appBar, { [classes.appBarShift]: sidebarState })} >
            <Toolbar className={classes.toolbar}>
                <div className={`left ${classes.dFlex} ${classes.alignItemsCenter}`}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => toggleSidebar()}
                        edge="start"
                        className={clsx(classes.menuButton, sidebarState && classes.hide)}
                    >
                        <FontAwesomeIcon icon={faSet.faBars} className="fa-xs" />
                    </IconButton>
                    <img src={favicon} alt="Logo" className={classes.toolbarLogo} />
                    <Typography variant="h2" noWrap className={classes.appTitle} style={{ color: theme.palette.main }}>
                        <b>{process.env.REACT_APP_TITLE}</b> - <small>Gestionale</small>
                    </Typography>
                </div>
                <div className="right">
                    <IconButton aria-label="logout" edge="end" color="inherit" onClick={() => setOpenDialog(true)}>
                        <FontAwesomeIcon icon={faSet.faSignOutAlt} className="fa-xs" />
                    </IconButton>
                </div>
            </Toolbar>
            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title"><b>{`${localStorage.user ? localStorage.user : 'User'}`}</b> - <small>Logout</small></DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Terminare la sessione?
                        </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)} color="primary">
                        No
                        </Button>
                    <Button onClick={() => { setOpenDialog(false); setDoLogout(true); }} color="primary" autoFocus>
                        Si
                        </Button>
                </DialogActions>
            </Dialog>
        </AppBar>
    )
}

export default NavTop;