import React, { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { Controller } from 'react-hook-form';

export default function MuiDatePicker(props) {
    const { defaultValue, ...pickerProps } = props;
    const { required } = props;
    const [val, setVal] = useState(props?.control?.getValues(props?.id) || null);
    const onDateChange = date => {
        if (date) {
            setVal(date);
            const val = new Date(date),
                d = new Date(val.getTime() - val.getTimezoneOffset() * 60000).toISOString()?.split('T')?.[0];
            // date = moment(date).format("yyy-MM-D, 00:00:00");
            // props.control.setValue(props.name, date);
            props.control.setValue(props.name, `${d}, 00:00:00`);
        }
    }

    return (
        <Controller
            name={props.name}
            control={props.control}
            initialFocusedDate={null}
            defaultValue={val}
            render={({ value }) => {
                // eslint-disable-next-line
                if (!val && value) setVal(value);
                return (<MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                        {...pickerProps}
                        value={val}
                        autoOk={true}
                        onChange={date => onDateChange(date)}
                        inputVariant={pickerProps.variant}
                        required={required}
                        autoComplete="off"
                    />
                </MuiPickersUtilsProvider>)
            }}
        />
    );
}