import React from 'react';
import { history } from "../index";
import store from './store';
import { publicRoute, privateRoute, loaderHide, loaderShow, loggedOut } from './store/slices';
import { useWatch } from 'react-hook-form';
import * as faBrands from '@fortawesome/free-brands-svg-icons'
import * as faPro from '@fortawesome/pro-regular-svg-icons'
import { makeNotify } from "../components/utilities/Notify";
import { Switch, TextField } from '@material-ui/core';

// Global vars
export const faSet = { ...faBrands, ...faPro };
export const logo = process.env.REACT_APP_LOGO;
export const favicon = process.env.REACT_APP_FAVICON;
export const disclaimer = process.env.REACT_APP_DISCLAIMER;
export const requiredErr = 'The field is required';
export const defProps = { variant: "outlined", margin: "dense", fullWidth: true, defaultValue: "", InputLabelProps: { shrink: true } }
export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * Global Functions
 */

// Logout
export const logout = () => {
    localStorage.removeItem("access_token");
    store.dispatch(loaderShow());
    setTimeout(() => {
        store.dispatch(loggedOut());
        history.go('/login');
    }, 800);
}

// Await timer
export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// Check if route is public
export const checkRoute = location => store.dispatch(location?.state?.isPublic ? publicRoute() : privateRoute());

// Loader
export const hideLoader = () => store.dispatch(loaderHide());
export const showLoader = () => {
    store.dispatch(loaderShow());
    setTimeout(() => {
        if (store.getState()?.Loader?.loading)
            hideLoader()
    }, 10000);
};

// Notify form errors
export const notifyFormErrors = errs => {
    // console.log(errs);
    errs = errs.map(err => document.querySelector(`label[for='${document.querySelector(`[name="${err.ref.name}"]`)?.id}']`)?.firstChild?.nodeValue || err.ref.name);
    if (errs.length) {
        let invalid_fields = '';
        for (const field of errs) invalid_fields += `- ${field}\n`;
        makeNotify(`Errors: \n ${invalid_fields}`);
    }
}

// Dependent fields - React Hook Form
export const DepField = props => {
    let { parentfield, parentprop, name, control } = props,
        val = control.getValues(parentfield) ? control.getValues(parentfield)[parentprop ? parentprop : name] : " ";
    if (val?.name) val = val.name;
    useWatch({ control, name: parentfield, defaultValue: val });
    if (val) control.setValue(name, val);
    return <TextField {...props} disabled />
}

// Form field error
export const fieldError = (name, errors) => ({
    helperText: errors[name] && errors[name].message,
    error: Boolean(errors[name])
})

// MaterialTable edit 
export const editNumber = row => <TextField defaultValue={row.value} onChange={e => row.onChange(e.target.value)} type="number" />
export const editSwitch = row => <Switch checked={row.value ? true : false} onChange={e => row.onChange(e.target.checked ? 1 : 0)} />