// UI --> action dispatch --> (reducer >) store

import { 
    configureStore, 
    // getDefaultMiddleware 
} from '@reduxjs/toolkit'; // ReduxToolkit - configureStore = createStore + combineReducers + thunk + devtools
import { MainSidebarReducer, LoaderReducer, LoginReducer, AppReducer, StepperReducer } from "./slices";

// const middlewares = [loggerMiddleware];
// function loggerMiddleware(store) { // log action dispatch
//     return function (next) {
//         return function (action) {
//             console.log(action);
//             return next(action);
//         }
//     }
// }

const store = configureStore({
    reducer: {
        MainSidebar: MainSidebarReducer,
        Loader: LoaderReducer,
        Login: LoginReducer,
        App: AppReducer,
        Stepper: StepperReducer
    },
    // middleware: [...getDefaultMiddleware(), ...middlewares]
});

export default store;