import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { faSet } from '../../app/globals';
import appRoutes from '../../app/routes';
import { history } from '../../index';
import { makeGlobalStyles } from '../../styles/Styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { NavLink, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Drawer, Fade, Popper, Paper, ClickAwayListener, List, Divider, IconButton, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { sidebarToggle, sidebarClose } from '../../app/store/slices';
import clsx from 'clsx';

function MainSidebar(props) {
    const sidebarState = useSelector(state => state.MainSidebar.open);
    const dispatch = useDispatch();
    const classes = makeGlobalStyles();
    const sidebarLinks = appRoutes.filter((route) => (route?.visibility?.includes('sidebar')));
    // Actions
    const toggleSidebar = () => dispatch(sidebarToggle());
    const closeSidebar = () => dispatch(sidebarClose());
    // Functions
    const closeSidebarMd = () => { if (isWidthDown('md', props.width)) closeSidebar() };
    // Hooks
    useEffect(() => {
        if (isWidthDown('md', props.width)) closeSidebar();
        // eslint-disable-next-line
    }, []);

    return (
        <Drawer
            className={classes.drawer}
            variant={isWidthDown('md', props.width) ? "temporary" : "persistent"}
            anchor="left"
            open={sidebarState}
            ModalProps={{ onBackdropClick: closeSidebarMd }}
            classes={{ paper: classes.drawerPaper }}
        >
            <div className={classes.drawerHeader}>
                <IconButton onClick={() => toggleSidebar()} className={classes.collapseBtn}>
                    <FontAwesomeIcon icon={faSet.faChevronLeft} className="fa-xs" />
                </IconButton>
            </div>
            <Divider />
            <List>
                {sidebarLinks.map(route => (
                    route.group
                        ? <MenuElem key={route.name} route={route} />
                        : <NavLink to={route.path.split(':')[0]} key={route.name} activeClassName={classes.selected}>
                            <MenuElem route={route} />
                        </NavLink>
                ))}
            </List>
        </Drawer>
    )
}

function MenuElem(props) {
    const classes = makeGlobalStyles();
    const isMounted = useRef(true)
    const { route } = props;
    const { children } = route;
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [arrow, setArrow] = useState(null);
    const [active, setActive] = useState(false);
    const activeIfChild = () => isMounted.current && setActive(history.location.state?.parentId === route.name ? true : false)
    // const clickHover = e => { if (children && e?.target?.click) e.target.click() }
    history.listen(() => activeIfChild());
    useEffect(() => { 
        activeIfChild();
        return () => isMounted.current = false;
        // eslint-disable-next-line
    }, [])
    
    return (
        <>
            <ListItem className={clsx(active && classes.selected)} button onClick={e => { setOpen(!open); setAnchorEl(e.currentTarget) }} /*onMouseEnter={e => !open && clickHover(e)}*/ >
                {route.icon ? <ListItemIcon><FontAwesomeIcon icon={route.icon} size="2x" /></ListItemIcon> : ''}
                <ListItemText primary={route.name} />
            </ListItem>
            {children ?
                <Popper
                    open={open} placement="bottom" anchorEl={anchorEl} transition /*onMouseLeave={e => open && clickHover(e)}*/
                    className={classes.popper} disablePortal modifiers={{ arrow: { enabled: true, element: arrow } }}
                >
                    {({ TransitionProps }) => (
                        <ClickAwayListener onClickAway={() => setOpen(false)}>
                            <Fade {...TransitionProps} timeout={250}>
                                <Paper className={classes.tooltip}>
                                    <span className={classes.arrow} ref={setArrow} />
                                    {children.map((child, index) => (
                                        <NavLink
                                            to={{ pathname: child.path, state: { parentId: route.name } }} key={index}
                                            activeClassName={classes.selected} onClick={() => setOpen(false)} className={classes.popperLink}
                                        >
                                            <MenuElem route={child} />
                                        </NavLink>
                                    ))}
                                </Paper>
                            </Fade>
                        </ClickAwayListener>
                    )}
                </Popper> : null}
        </>
    )
}

export default withRouter(withWidth()(MainSidebar));