import React, { useEffect, useState } from 'react';
import { makeGlobalStyles } from '../../../../styles/Styles';
import { Step, Stepper, StepLabel, StepContent, Paper, Button } from '@material-ui/core';
import { isObject } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { setStepAction, setStepOk } from '../../../../app/store/slices';

export default function VerticalStepper(props) {
    const classes = makeGlobalStyles();
    const dispatch = useDispatch();
    const stepOk = useSelector(state => state.Stepper.stepOk);
    const stepAction = useSelector(state => state.Stepper.stepAction);
    const externalManagement = useSelector(state => state.Stepper.externalManagement);
    const { wrapClass, getSteps, getStepContent, changeStep, onFinish, isSubmitted } = props;
    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps().map(x => isObject(x) ? x.title : x);
    const handleReset = () => setActiveStep(0);
    useEffect(() => {
        if (stepOk && stepAction) {
            switch (stepAction) {
                case 'next':
                    setActiveStep(prevActiveStep => externalManagement ? steps.length : prevActiveStep + 1); // next step
                    dispatch(setStepOk(false)); dispatch(setStepAction(undefined));
                    break;
                case 'back':
                    setActiveStep(prevActiveStep => prevActiveStep - 1); // back step
                    dispatch(setStepOk(false)); dispatch(setStepAction(undefined));
                    break;
                default: return;
            }
        }
    }, [stepOk, stepAction, steps, externalManagement, dispatch])

    return (
        <div className={classes[wrapClass]}>
            <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((label, index) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                        <StepContent className="stepContent">
                            {getStepContent(index)}
                            <div className="stepActions">
                                <div>
                                    <Button disabled={activeStep === 0} onClick={async () => changeStep(activeStep, 'back')}>Back</Button>
                                    <Button variant="contained" color="primary" onClick={async () => changeStep(activeStep, 'next')}>
                                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                    </Button>
                                </div>
                            </div>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
            {activeStep === steps.length && (
                <Paper square elevation={0} className={`${classes.w_100} stepContent`} style={{ textAlign: "center" }}>
                    <h2>All steps completed</h2>
                    {!isSubmitted && <div className="stepActions">
                        <Button onClick={handleReset}>Review</Button>
                        <Button variant="contained" color="primary" onClick={onFinish}>Submit</Button>
                    </div>}
                </Paper>
            )}
        </div>
    );
}
