import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
const isEdu = process.env.REACT_APP_IS_EDU === "true" ? true : false;

// global vars
export const drawerWidth = 250;

// mui theme
export const muiTheme = isEdu
    ? createMuiTheme({
        spacing: 4,
        palette: {
            type: "dark",
            grey: { 900: "#121212" },
            text: {
                primary: '#fff!important',
                disabled: "#ffffff80!important"
            },
            primary: { main: '#0071BD' },
            secondary: {
                light: '#3a488a',
                main: '#38a3ff',
                contrastText: '#fff',
            },
            background: { paper: "#4c4c4c" },
            action: { active: "#fff!important" },
            contrastThreshold: 3,
            tonalOffset: 0.2,
        }
    })
    : createMuiTheme({
        type: "light",
        spacing: 4,
        palette: {
            primary: { main: '#981d97' },
            secondary: {
                light: '#d673d5',
                main: '#d497d3',
                contrastText: '#fff',
            },
            background: { paper: "#fff" },
            contrastThreshold: 3,
            tonalOffset: 0.2,
        }
    });

// styles
const globalStyles = theme => ({
    // General
    dFlex: {
        display: 'flex!important',
    },
    dNone: {
        display: 'none!important',
    },
    justifyContentCenter: {
        justifyContent: "center!important"
    },
    alignItemsCenter: {
        alignItems: 'center!important'
    },
    flexWrap: {
        flexWrap: "wrap!important",
        '& [class*="MuiToolbar-root"]': { // Table Toolbar
            flexWrap: "wrap!important",
            padding: "10px"
        },
        '& .MuiTableCell-footer': { // Table Footer
            overflowX: 'hidden!important'
        }
    },
    flexColumn: {
        flexDirection: "column!important"
    },
    textCenter: {
        textAlign: 'center!important'
    },
    w_100: {
        width: '100%!important'
    },
    fullPage: {
        background: isEdu ? '#4c4c4c' : '#fff',
        minHeight: '100vh',
        width: '100%',
        display: 'inline-block',
        '& :-webkit-autofill': {
            boxShadow: `0 0 0px 1000px ${theme.palette.background.paper} inset !important`
        }
    },
    m0a: {
        marginTop: "0",
        marginBottom: "0",
        marginLeft: "auto!important",
        marginRight: "auto!important"
    },
    dnone: {
        display: 'none!important'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    paperBg: {
        backgroundColor: `${theme.palette.background.paper}!important`
    },
    notifySnackbar: {
        whiteSpace: 'pre-line'
    },
    iconCompleted: {
        fontSize: '37px',
        margin: '0 7px',
        color: 'green'
    },
    // Loader
    loader: {
        position: 'fixed',
        height: '100%',
        width: '100%',
        top: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(0,0,0,.4)',
        zIndex: '9999'
    },
    // Login
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    // MainSidebar
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        [theme.breakpoints.down('md')]: {
            width: 0
        }
    },
    drawerPaper: {
        width: drawerWidth,
        background: `${theme.palette.background.paper}!important`
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end'
    },
    collapseBtn: {
        height: '40px',
        width: '40px',
        paddingLeft: '10px'
    },
    selected: {
        backgroundColor: '#989898!important',
        color: 'rgba(0, 0, 0, 0.7)!important',
        '& .MuiListItem-root ': {
            backgroundColor: '#989898!important',
            color: 'rgba(0, 0, 0, 0.7)!important'
        }
    },
    link: {
        textDecoration: 'none'
    },
    // NavTop
    appBar: {
        transition: `${theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })}!important`
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)!important`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        [theme.breakpoints.down('md')]: {
            width: "100%!important",
            marginLeft: 0
        }
    },
    appTitle: {
        fontSize: "26px!important",
        [theme.breakpoints.down('sm')]: {
            fontSize: "20px!important",
        }
    },
    menuButton: {
        marginRight: theme.spacing(2)
    },
    hide: {
        display: 'none'
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    toolbarLogo: {
        height: '30px',
        marginRight: '10px',
        marginTop: isEdu ? '-8px' : 0,
        filter: 'brightness(0) invert(1)'
    },
    // MainContent
    mainContent: {
        background: theme.palette.background.paper
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(2),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
        [theme.breakpoints.down('md')]: {
            marginLeft: 0
        },
        minHeight: `calc(100vh - ${theme.spacing(2) * 2}px)`,
        display: "table",
        width: `calc(100% - ${theme.spacing(2) * 2}px)`
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    // Student Stepper
    studentFormStepper: {
        '& .stepActions': {
            marginTop: "20px"
        },
        '& .MuiPaper-root': {
            background: `${theme.palette.background.paper}!important`
        },
        [theme.breakpoints.up('lg')]: {
            minHeight: `100vh`,
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            '& .MuiStepper-horizontal': {
                alignSelf: "flex-start",
                width: "100%"
            },
            '& .stepContent': {
                width: "100%",
                maxWidth: "700px",
                margin: "0 auto",
                padding: "30px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "center"
            },
            '& .stepActions': {
                display: "flex",
                justifyContent: "center"
            },
            "& .Mui-error": {
                whiteSpace: 'pre-line!important'
            },
        }
    },
    stepperHeader: {
        borderBottom: "solid 1px #ccc",
        marginBottom: "20px",
        [theme.breakpoints.down('md')]: {
            textAlign: "center",
            marginTop: "20px"
        }
    },
    stepperLogo: {
        backgroundSize: "contain!important",
        height: "100px",
        margin: "0 auto 20px auto",
    },
    stepFields: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center"
    },
    stepFormField: {
        flexBasis: "300px",
        width: "300px!important",
        maxWidth: "100%!important",
        margin: "10px!important"
    },
    // Table
    materialTable: {
        "& tbody tr:not([path])": {
            display: "none"
        },
    },
    compactView: {
        "& tbody td": {
            padding: "10px!important",
            fontSize: "14px!important"
        },
        "& tbody .MuiIconButton-root": {
            margin: "0!important"
        }
    },
    tableToolbar: {
        '& div > span': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap'
        }
    },
    // Form
    formBlock: {
        display: 'flex',
        flexDirection: 'column',
        padding: '30px',
        border: 'solid 1px #ccc',
        position: 'relative',
        margin: '20px',
        '&:before': {
            content: 'attr(data-name)',
            position: 'absolute',
            top: '0',
            transform: 'translateY(-50%)',
            color: isEdu ? '#fff' : '#000',
            background: theme.palette.background.paper,
            // background: '#fafafa',
            padding: '0 10px',
            fontSize: '20px'
        },
        '&.tab-formBlock:before': {
            backgroundColor: theme.palette.background.paper
        },
        '&>div:not(:last-child)': {
            marginBottom: '10px'
        },
        "& .Mui-error": {
            whiteSpace: 'pre-line'
        }
    },
    formBlockWithTable: {
        flexGrow: "1",
        flexBasis: "100%",
        padding: '30px 5px 5px 5px',
        width: 'calc(100% - 50px)' // 100% - padding - margin
    },
    formActions: {
        display: 'flex',
        width: '100%',
        marginBottom: "20px",
        flexWrap: "wrap",
        justifyContent: "center",
        "& > button": {
            marginBottom: "10px"
        }
    },
    tabPanel: {
        width: "100%",
        padding: `${theme.spacing(2)}px 0!important`,
        backgroundColor: `${theme.palette.background.paper}!important`
    },
    // 404
    page404: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        zIndex: '99999999',
        background: '#fff',
        color: '#000',
        '& h1': {
            fontSize: '40px',
            margin: '5px'
        },
        '& h3': {
            display: 'inline-flex',
            alignItems: 'center'
        },
        '& pre': { margin: '0 5px' }
    },
    // Popper tooltip
    popper: {
        zIndex: 9999,
        '&[x-placement*="bottom"] $arrow': {
            top: 0,
            left: 0,
            marginTop: '-0.9em',
            width: '3em',
            height: '1em',
            filter: 'drop-shadow(0 -0.5px .5px rgba(0,0,0,.2))',
            '&::before': {
                borderWidth: '0 1em 1em 1em',
                borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
            },
        },
        '&[x-placement*="top"] $arrow': {
            bottom: 0,
            left: 0,
            marginBottom: '-1em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '1em 1em 0 1em',
                borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
            },
        },
        '&[x-placement*="right"] $arrow': {
            left: 0,
            marginLeft: '-1em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 1em 1em 0',
                borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
            },
        },
        '&[x-placement*="left"] $arrow': {
            right: 0,
            marginRight: '-1em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 0 1em 1em',
                borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
            },
        },
    },
    tooltip: {
        minWidth: drawerWidth,
        transform: 'translateX(4px)'
    },
    popperLink: {
        '& > div': {
            background: theme.palette.background.paper,
            textAlign: 'center!important'
        }
    },
    arrow: {
        position: 'absolute',
        fontSize: 7,
        width: '3em',
        height: '3em',
        filter: 'drop-shadow(0 0 .5px rgba(0,0,0,.2))',
        '&::before': {
            content: '""',
            margin: 'auto',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid',
        }
    },
    linkUnderline: {
        '& a': {
            textDecoration: "underline"
        }
    }
});

export const makeGlobalStyles = makeStyles(() => globalStyles(muiTheme));   // functional Components
export default globalStyles;                                                // class Components
