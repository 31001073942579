import React, { useEffect, useState } from 'react';
import { makeGlobalStyles } from '../../../../styles/Styles';
import { Step, Stepper, StepLabel, Button } from '@material-ui/core';
import { isObject } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { setStepAction, setStepOk } from '../../../../app/store/slices';

export default function HorizontalStepper(props) {
    const classes = makeGlobalStyles();
    const dispatch = useDispatch();
    const stepOk = useSelector(state => state.Stepper.stepOk);
    const stepAction = useSelector(state => state.Stepper.stepAction);
    const externalManagement = useSelector(state => state.Stepper.externalManagement);
    const { wrapClass, getSteps, getStepContent, changeStep, onFinish, isSubmitted } = props;
    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps().map(x => isObject(x) ? x.title : x);
    const handleReset = () => setActiveStep(0);
    useEffect(() => {
        if (stepOk && stepAction) {
            switch (stepAction) {
                case 'next':
                    setActiveStep(prevActiveStep => externalManagement ? steps.length : prevActiveStep + 1); // next step
                    dispatch(setStepOk(false)); dispatch(setStepAction(undefined));
                    break;
                case 'back':
                    setActiveStep(prevActiveStep => prevActiveStep - 1); // back step
                    dispatch(setStepOk(false)); dispatch(setStepAction(undefined));
                    break;
                default: return;
            }
        }
    }, [stepOk, stepAction, steps, externalManagement, dispatch])

    return (
        <div className={classes[wrapClass]}>
            <Stepper activeStep={activeStep} alternativeLabel={true}>
                {steps.map((label, index) => {
                    let stepProps = {};
                    let labelProps = {};
                    return <Step key={label} {...stepProps}><StepLabel {...labelProps}>{label}</StepLabel></Step>;
                })}
            </Stepper>
            <div className={classes.w_100}>
                {activeStep === steps.length
                    ? (
                        <div className="stepContent">
                            <h2>All steps completed</h2>
                            {!isSubmitted && <div className="stepActions">
                                <Button onClick={handleReset}>Review</Button>
                                <Button variant="contained" color="primary" onClick={onFinish}>Submit</Button>
                            </div>}
                        </div>
                    )
                    : (
                        <div className="stepContent">
                            {getStepContent(activeStep)}
                            <div className="stepActions">
                                <Button disabled={activeStep === 0} onClick={async () => changeStep(activeStep, 'back')}>Back</Button>
                                <Button variant="contained" color="primary" onClick={async () => changeStep(activeStep, 'next')}>
                                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                </Button>
                            </div>
                        </div>
                    )}
            </div>
        </div>
    );
}
